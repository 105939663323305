@import url("https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800;900&display=swap");

/* Reset CSS */
* {
    padding: 0px;
    margin: 0px;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
}

.accordion {
    --bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23fff%27%3e%3cpath fill-rule=%27evenodd%27 d=%27M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z%27/%3e%3c/svg%3e");
    --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 16 16%27 fill=%27%23fff%27%3e%3cpath fill-rule=%27evenodd%27 d=%27M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z%27/%3e%3c/svg%3e");
}

*:focus {
    outline: 0;
}

html,
body {
    /* overflow-x: hidden; */
}

body {
    margin: 0;
    padding: 0;
    font-size: 15px;
    font-family: "Inter", sans-serif;
    min-height: 100vh;
    color: #fff;
}

ol,
ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

:focus {
    outline: 0;
}

a,
a:link {
    text-decoration: none;
    outline: none;
}

.menu_bar {
    display: none;
}

p {
    padding: 2px 0;
    margin: 0;
}

a:focus {
    outline: none !important;
}

/* :hover {
    transition: all 0.5s ease;
    -webkit-transition: all 0.5s ease;
    -moz-transition: all 0.5s ease;
} */

img {
    border: 0;
    max-width: 100%;
    height: auto;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0;
    padding: 0;
    font-weight: 400;
}

/* Reset CSS */

body.modal-open {
    padding: 0 !important;
}

.modal-backdrop {
    background: rgba(26, 25, 46, 0.85) !important;
    backdrop-filter: blur(10px);
    opacity: 1 !important;
}

.site-wrap.for-dashboard {
    /* background: url('../src/assets/images/dash-bg.jpg') top center no-repeat;
    background-size: cover; */
}

.container {
    max-width: 1310px;
}

.theme-btn {
    display: inline-block;
    border: none;
    cursor: pointer;
    background: linear-gradient(90deg,
            var(--primary) 0.08%,
            var(--secondary) 101.7%);
    border-radius: 50px;
    font-size: 15px;
    font-weight: 600;
    color: var(--white);
    padding: 12px 20px;
    text-align: center;
    position: relative;
    overflow: hidden;
    z-index: 0;
}

.theme-btn::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 0;
    opacity: 0;
    height: 100%;
    background: var(--white);
    transition: all 0.5s ease;
}

.theme-btn:hover {
    color: var(--secondary);
}

.theme-btn:hover::before {
    width: 100%;
    opacity: 1;
}

.theme-btn span {
    position: relative;
    z-index: 1;
}

.theme-btn.full {
    display: block;
    width: 100%;
    padding-left: 0;
    padding-right: 0;
}

.theme-btn svg {
    font-size: 18px;
    transform: translateY(-1px);
}

.theme-btn.bdr {
    background: none;
    border: 1px solid var(--primary);
}

.theme-btn.connected {
    background: var(--charcoal);
    color: #fff !important;
    border: 1px solid var(--charcoal) !important;
}

.theme-btn.connected::before {
    opacity: 0;
}

.theme-btn.large {
    padding-top: 14px;
    padding-bottom: 14px;
    min-width: 85px;
}

.theme-btn.btn-min {
    min-width: 180px;
}

.theme-btn.red {
    background: red;
}

.login-wrap {
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 110px 0;
}

/* .login-box{ background: var(--box); width: 416px; margin: 0 auto; border-radius: 12px; padding: 30px;} */
.login-box {
    width: 416px;
    margin: 0 auto;
    padding: 0 30px;
}

.symbol {
    width: 80px;
    height: 80px;
    margin: 0 auto;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(90deg,
            var(--primary) 0.08%,
            var(--secondary) 101.7%);
    font-size: 40px;
}

.login-box h2 {
    font-size: 20px;
    font-weight: 600;
    letter-spacing: -0.2px;
    padding-top: 22px;
    text-align: center;
}

.input-wrap {
    margin-top: 20px;
}

.input-wrap label {
    font-size: 15px;
    padding-bottom: 12px;
    display: block;
}

.inp-outer {
    padding: 2px;
    background: var(--light-grey);
    border-radius: 8px;
    position: relative;
}

.inp {
    width: 100%;
    background: var(--body);
    border-radius: 7px;
    border: none;
    color: var(--white);
    font-size: 15px;
    font-weight: 400;
    padding: 10px 16px;
}

.inp.radius {
    border-radius: 50px;
}

.inp-outer:has(> .inp:focus) {
    background: linear-gradient(90deg,
            var(--primary) 0.08%,
            var(--secondary) 101.7%);
}

textarea.inp {
    height: 150px;
    display: block;
}

.input-wrap.alt .inp-outer {
    border-radius: 50px;
}

.input-wrap.alt .inp {
    border-radius: 50px;
}

.inp-icon {
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
    display: inline-block;
    font-size: 22px;
}

.or {
    position: relative;
    font-size: 12px;
    text-align: center;
}

.or span {
    display: inline-block;
    background: var(--body);
    padding: 0 12px;
    position: relative;
    z-index: 1;
}

.or::after {
    content: "";
    position: absolute;
    left: 0;
    top: 8px;
    width: 100%;
    height: 1px;
    background: rgba(255, 255, 255, 0.14);
}

.social-sign {
    position: relative;
    margin-top: 20px;
}

.social-sign button {
    width: 100%;
    text-align: center;
    background: var(--crayola);
    border: none;
    color: #fff;
    padding: 9px 30px;
    border-radius: 50px;
    font-size: 14px;
}

.social-sign button:hover {
    background: linear-gradient(90deg,
            var(--primary) 0.08%,
            var(--secondary) 101.7%) border-box;
}

.social-sign-icon {
    position: absolute;
    left: 15px;
    top: 50%;
    transform: translateY(-50%);
    transition: all 0.5s ease;
}

.log-option {
    margin-top: 20px;
    color: rgba(255, 255, 255, 0.7);
}

.log-option a {
    color: var(--white);
}

.log-option a:hover {
    color: var(--primary);
}

.site-header {
    position: fixed;
    width: 100%;
    left: 0;
    top: 0;
    z-index: 9;
    transition: none;
    backdrop-filter: blur(0.5rem);
}

.header-in {
    max-width: 1750px;
    margin: 0 auto;
}

.site-header.fixed {
    background: rgba(255, 255, 255, 0.02);
    backdrop-filter: blur(0.5rem);
}

.site-header-main {
    height: 90px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.user-img-in {
    width: 32px;
    height: 32px;
}

.user-img-in img {
    width: 32px;
    height: 32px;
    border-radius: 100%;
    object-fit: cover;
}

.profile-link .dropdown-toggle {
    display: inline-flex;
    align-items: center;
    background: none;
    border: none;
    margin: 0;
    padding: 0;
    color: #fff;
}

.profile-link .dropdown-toggle:focus {
    outline: none;
}

.profile-link {
    min-width: 150px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.profile-link .dropdown-menu {
    width: 236px;
    background: var(--dark-blue);
    border-radius: 20px;
    padding: 20px;
}

.profile-link .dropdown-menu ul li:first-child {
    margin-top: 0;
}

.profile-link .dropdown-menu ul li {
    margin-top: 10px;
}

.profile-link .dropdown-menu ul li a {
    color: var(--white);
    font-size: 14px;
    display: flex;
    align-items: center;
}

.profile-link .dropdown-menu ul li a:hover {
    color: #8fa2ff;
}

.profile-link .dropdown-menu ul li a span {
    font-size: 20px;
    padding-right: 10px;
}

.site-nav {
    border: 1px solid #8fa2ff;
    border-radius: 50px;
}

.site-nav>ul {
    display: flex;
    align-items: center;
}

.site-nav>ul>li {
    margin: 0 25px;
    position: relative;
}

.site-nav>ul>li>a {
    color: var(--white);
    display: inline-block;
    padding: 10px 0;
}

.site-nav>ul>li>a:hover {
    color: #8fa2ff;
}

.site-nav>ul>li>a.active {
    color: #8fa2ff;
    font-weight: 700;
}

.site-nav>ul>li>ul {
    position: absolute;
    left: 0;
    top: 100%;
    background: var(--dark-blue);
    border-radius: 5px;
    border: 1px solid #262f5d;
    min-width: 220px;
    overflow: hidden;
    opacity: 0;
    visibility: hidden;
}

.site-nav>ul>li>ul>li>a {
    display: block;
    color: #fff;
    padding: 12px 15px;
    border-bottom: 1px solid #262f5d;
    font-size: 13px;
    position: relative;
    background: #100f27;
    z-index: 1;
}

.site-nav>ul>li>ul>li:last-child>a {
    border: none;
}

.site-nav>ul>li>ul>li>a:hover {
    background: #8fa2ff;
}

.site-nav>ul>li:hover>ul {
    opacity: 1;
    visibility: visible;
}

.site-wrap {
    padding-top: 100px;
    padding-bottom: 80px;
}

/* .welcome {
    padding-top: 160px;
} */

.welcome-top {
    max-width: 810px;
    /* text-align: center; */
    margin: 0 auto;
}

.welcome-top h1 {
    font-size: 60px;
}

.welcome-top h1 span {
    font-weight: 600;
}

.welcome-top h1 span.green {
    font-weight: 400;
    color: #8fa2ff;
}

.welcome-top p {
    padding: 15px 0 0 0;
}

.hero-banner .container {
    height: 100%;
    display: flex;
    align-items: center;
    width: 100%;
}

.hero-banner .row {
    width: 100%;
}

.welcome-video {
    max-width: 1200px;
    margin: 0 auto;
    margin-top: 45px;
}

.welcome-video-in {
    height: 390px;
    border-radius: 20px;
    overflow: hidden;
    position: relative;
    border: 1px solid #8fa2ff;
    z-index: 5;
}

.welcome-video-in::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: linear-gradient(180deg,
            rgba(29, 27, 65, 0.63) 0%,
            #3300d0 119.7%);
    /* opacity: 0.8; */
    opacity: 0.4;
}

.welcome-video-in video {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.play-btn {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    z-index: 2;
    cursor: pointer;
    transition: all 5s ease;
}

.play-btn img {
    width: 95px;
}

.created-by {
    max-width: 1750px;
    margin: 0 auto;
    margin-top: 100px;
}

.create-details {
    border-radius: 20px;
    border: 1px solid rgba(29, 27, 65, 0.63);
    background: rgba(29, 27, 65, 1);
    height: 243px;
    margin-top: 30px;
    padding: 0 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    overflow: hidden;
    position: relative;
    transition: none;
}

.create-details:hover .card__line {
    /* display: none; */
    opacity: 0;
}

.create-details:hover {
    border: 3px solid #8fa2ff;
}

.create-details-top {
    align-self: stretch;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.create-details-bottom {
    align-self: stretch;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;
}

.create-details h3 {
    font-size: 20px;
    font-weight: 600;
    color: var(--white);
}

.create-details h4 {
    font-size: 64px;
    color: rgba(255, 255, 255, 0.3);
    font-weight: 700;
}

.create-details p {
    font-weight: 300;
    opacity: 0.7;
    max-width: 75%;
    font-size: 14px;
    color: var(--white);
}

.create-details-bottom span {
    font-size: 60px;
    color: var(--white);
}

.video-project-wrap {
    margin-top: 10px;
}

.video-project {
    max-width: 1750px;
    margin: 0 auto;
    margin-top: 100px;
}

.video-project-single {
    background: #100f27;
    border-radius: 20px;
    margin-top: 30px;
}

.video-project-16x9 {
    background: #100f27;
    border-radius: 20px;
    margin-top: 30px;
    max-width: 435px;
    height: 300px;
    margin-right: auto;
    margin-left: auto;
}

.video-project-img_16x9 {
    height: 217px;
    border-radius: 20px 20px 0px 0px;
    overflow: hidden;
}

.video-project-img_16x9 img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.video-project-9x16 {
    background: #100f27;
    border-radius: 20px;
    max-width: 245px;
    height: 300px;
    margin-top: 30px;
    margin-right: auto;
    margin-left: auto;
    overflow: hidden;
}

.video-project-img_9x16 {
    width: 100%;
    height: 217px;
}

.video-project-img_9x16 img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.video-project-img {
    height: 300px;
    border-radius: 20px 20px 0px 0px;
    overflow: hidden;
}

.video-project-img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.video-project-wrap .col-lg-3 {
    /* width: 20%; */
}

.video-project-bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 14px 14px 14px;
}

.video-project-bottom h6 {
    font-size: 14px;
}

.video-project-bottom p {
    font-weight: 300;
    font-size: 12px;
    padding: 0;
}

.drop-style-1 .dropdown-toggle:focus {
    outline: none;
}

.drop-style-1 .dropdown-toggle {
    border: none;
    background: none;
    margin: 0;
    padding: 0;
    color: var(--white);
    font-size: 20px;
}

.drop-style-1 .dropdown-toggle::after {
    display: none;
}

.drop-style-1 .dropdown-menu {
    background: var(--dark-blue);
    border-radius: 20px;
    padding: 15px;
    left: inherit !important;
    right: 0 !important;
}

.drop-style-1 .dropdown-menu ul li {
    margin-top: 16px;
}

.drop-style-1 .dropdown-menu ul li:first-child {
    margin-top: 0;
}

.drop-style-1 .dropdown-menu ul li a {
    display: flex;
    align-items: center;
    color: var(--white);
    font-size: 14px;
}

.drop-style-1 .dropdown-menu ul li a:hover {
    color: #8fa2ff;
}

.drop-style-1 .dropdown-menu ul li a svg {
    font-size: 20px;
    margin-right: 10px;
}

.image-block {
    max-width: 1200px;
    margin: 0 auto;
}

.image-block-single {
    margin-top: 90px;
}

.image-block-left {
    padding-right: 120px;
}

.image-block-left h5 {
    font-size: 16px;
    color: #8fa2ff;
    font-weight: 500;
}

.image-block-left h6 {
    font-size: 21px;
    font-weight: 600;
    padding-top: 10px;
}

.image-block-left p {
    font-weight: 300;
    font-size: 14px;
    padding: 15px 0 0 0;
}

.image-block-left a {
    font-size: 21px;
    font-weight: 600;
    color: var(--white);
    display: inline-block;
    margin-top: 20px;
}

.image-block-left a:hover {
    color: #8fa2ff;
}

.image-block-right {
    text-align: right;
}

.image-block-single:nth-child(even) [class*="col-"] {
    order: 2;
}

.image-block-single:nth-child(even) [class*="col-"]+[class*="col-"] {
    order: 1;
}

.image-block-single:nth-child(even) .image-block-right {
    text-align: left;
}

.image-block-single:nth-child(even) .image-block-left {
    padding-right: 0;
    padding-left: 120px;
}

.webnair {
    background: url("../src/assets/images/pattern.png") no-repeat top center;
    background-size: 100% auto;
    padding: 200px 0 70px 0;
}

.webnair-in {
    max-width: 1500px;
    margin: 0 auto;
    border-radius: 20px;
    background: rgba(29, 27, 65, 0.3);
    box-shadow: 0px 4px 200px 0px rgba(0, 0, 0, 0.25);
    backdrop-filter: blur(17px);
    padding: 90px 0;
}

.webnair-main {
    max-width: 1200px;
    margin: 0 auto;
}

.webnair-right h5 {
    font-size: 16px;
    color: #8fa2ff;
    font-weight: 500;
}

.webnair-right h6 {
    font-size: 21px;
    font-weight: 600;
    padding-top: 10px;
}

.webnair-right p {
    font-weight: 300;
    font-size: 14px;
    padding: 15px 0 0 0;
}

.site-footer {
    background: linear-gradient(45deg,
            rgba(16, 15, 39, 0.5) 0%,
            rgba(55, 74, 198, 0.2) 100%);
    padding: 20px 0;
}

.footer-main {
    max-width: 1750px;
    margin: 0 auto;
}

.footer-in {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.footer-left p {
    margin: 0;
    padding: 0;
    font-weight: 300;
    font-size: 13px;
}

.footer-left p a {
    color: #8fa2ff;
}

.footer-right ul {
    display: flex;
    align-items: center;
}

.footer-right ul li a {
    color: var(--white);
    font-size: 13px;
}

.footer-right ul li a:hover {
    color: #8fa2ff;
}

.footer-right ul li {
    margin-left: 30px;
}

.footer-right ul li:first-child {
    margin: 0;
}

.footer-right ul li a img {
    width: 150px;
}

.logo img {
    width: 195px;
}

.site-wrap {
    min-height: calc(100vh - 81px);
}

.site-container {
    max-width: 1750px;
    margin: 0 auto;
}

.inpx {
    font-family: "Inter", sans-serif;
    width: 100%;
    border-radius: 60px;
    border: 1px solid rgb(49 47 110);
    background: rgba(29, 27, 65, 0.3);
    padding: 13px 30px;
    color: var(--white);
}

textarea.inpx {
    border-radius: 16px;
    height: 250px;
}

textarea.large {
    height: 400px;
}

.inpx-wrap {
    position: relative;
    margin-top: 20px;
}

.inpx-icon {
    position: absolute;
    left: 30px;
    top: 50%;
    transform: translateY(-53%);
    font-size: 22px;
}

.inpx-wrap.icon-left .inpx {
    padding-left: 60px;
}

.project-search h2 {
    font-size: 24px;
    font-weight: 500;
}

.project-search .theme-btn {
    padding-top: 14px;
    padding-bottom: 14px;
}

select.inpx {
    background: url("../src/assets/images/icon/select.png") right 25px center no-repeat rgba(29, 27, 65, 0.3);
    appearance: none;
}

.integration {
    margin-top: 30px;
}

.integration-single {
    height: 156px;
    border-radius: 30px;
    background: #d7d5ff08;
    border: 1px solid #5d5a97;
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 20px;
}

.integration-single:hover {
    border: 1px solid var(--primary);
}

.integration-single p {
    font-weight: 500;
    padding-top: 15px;
}

.integration ul {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    margin: 0 -10px;
}

.integration ul li {
    padding: 0 10px;
    width: 25%;
}

.integration h2,
.connection h2 {
    font-size: 24px;
    font-weight: 500;
}

.connection {
    margin-top: 50px;
}

.connection-single {
    margin-top: 20px;
    border-radius: 30px;
    background: #d7d5ff08;
    border: 1px solid #5d5a97;
    min-height: 100%;
    display: flex;
    align-items: normal;
    flex-wrap: wrap;
    justify-content: space-between;
    flex-direction: column;
    width: 100%;
    padding: 50px 50px;
}

.connection-single:hover {
    border: 1px solid #8fa2ff;
}

.connection-btn.connected {
    margin-bottom: 10px;
}

.connection-left {
    display: flex;
    align-items: center;
}

.connection-logo {
    margin-right: 15px;
}

.connection-right button {
    border-radius: 36px;
    background: rgba(255, 255, 255, 0.2);
    border: none;
    color: var(--white);
    padding: 10px 25px;
}

.connection-right button.connected {
    background: linear-gradient(90deg,
            var(--primary) 0.08%,
            var(--secondary) 101.7%);
    color: #fff;
}

.connection-btn {
    border-radius: 36px;
    background: rgba(255, 255, 255, 0.2);
    border: none;
    color: var(--white);
    padding: 10px 25px;
}

.connection-btn.connected {
    background: linear-gradient(90deg,
            var(--primary) 0.08%,
            var(--secondary) 101.7%);
    color: #ffffff;
    position: relative;
    overflow: hidden;
}

.connection-btn.connected span {
    position: relative;
    z-index: 1;
}

.connection-btn.connected:hover {
    color: var(--secondary);
}

.connection-btn.connected::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 0;
    opacity: 0;
    height: 100%;
    background: var(--white);
    transition: all 0.5s ease;
}

.connection-btn.connected:hover::before {
    width: 100%;
    opacity: 1;
}

.p_not {
    padding: 0 !important;
}

.help_model .modal-dialog {
    max-width: 900px !important;
}

.connection-btn.dark {
    background: #8fa2ff;
    color: var(--dark-blue);
    font-weight: 500;
}

.facebook-delete {
    margin-left: 25px;
}

.theme-modal .modal-dialog {
    max-width: 630px;
}

.theme-modal .modal-content {
    background: var(--dark-blue);
    border: none;
    box-shadow: none;
    border-radius: 30px;
    padding: 15px;
}

.modal-body h2 {
    font-size: 24px;
    font-weight: 500;
}

.group-btn {
    margin-top: 50px;
}

.common-inp {
    width: 100%;
    border-radius: 10px;
    border: 1px solid rgba(255, 255, 255, 0.5);
    background: rgba(29, 27, 65, 0.3);
    padding: 12px 20px;
    color: var(--white);
}

/* .common-inp:focus {
    border: 1px solid #8fa2ff !important;
} */

.common-inp.alt {
    border: 1px solid rgb(49 47 110);
    background: rgba(29, 27, 65, 0.63);
}

.common-inp.alt::-webkit-calendar-picker-indicator {
    filter: invert(1);
}

.modal-close {
    position: absolute;
    right: 0;
    top: -5px;
    font-size: 22px;
    cursor: pointer;
}

.create-cont {
    margin-top: 30px;
}

.create-cont h2 {
    font-size: 24px;
    font-weight: 500;
}

.top-bar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 20px;
}

.top-bar-right {
    display: flex;
    align-items: center;
}

.top-bar-right .inpx {
    width: 160px;
}

.top-bar-right>* {
    margin-left: 10px;
}

.dimension {
    height: 227px;
    border-radius: 16px;
    background: rgba(29, 27, 65, 0.63);
    border: 2px solid rgba(29, 27, 65, 0.63);
    margin-top: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    text-align: center;
}

.dimension h5 {
    font-size: 32px;
    font-weight: 500;
}

.dimension p {
    font-weight: 300;
}

.dimension.select {
    border: 2px solid #8fa2ff;
}

.template-list {
    padding-top: 50px;
}

.template-list h2 {
    font-size: 24px;
    font-weight: 500;
}

.template-list ul {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin: 0 -10px;
}

.template-list ul li {
    padding: 0 10px;
    width: 16.65%;
}

.template-single {
    margin-top: 20px;
}

.template-single_dynmic {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
}

.template-img {
    height: 640px;
    border-radius: 20px;
    width: 360px;
    overflow: hidden;
}

.template-img_16X9 {
    max-width: 635px !important;
    height: 360px !important;
}

.template-img_16X9 img {
    border-radius: 20px;
}

.template-img img {
    width: 100%;
    height: 100%;
    /* object-fit: contain; */
    border-radius: 20px;
}

.site-container.for-editor {
    max-width: fit-content;
    padding: 0 14px;
}

.editor-wrap {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
    margin-top: 15px;
}

.editor-left {
    width: 400px;
    display: flex;
    justify-content: space-between;
}

.editor-left-nav {
    width: 84px;
    padding-top: 2px;
}

.innerTab.full2 .nav-item {
    margin-bottom: 10px;
    padding: 0 2px;
}

.editor-left-nav i {
    font-size: 20px;
}

.editor-left-cont {
    width: 100%;
    background: var(--dark-blue);
    border-radius: 25px;
    padding: 18px;
}

.navTab {
    display: block !important;
}

.navTab .nav-link {
    height: 52px;
    width: 52px;
    text-align: center;
    color: var(--white);
    font-size: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 16px;
    transition: all 0.5s ease;
    padding: 0;
}

.navTab .nav-link.active {
    height: 52px;
    width: 52px;
    background: rgba(29, 27, 65, 0.63);
    color: #8fa2ff;
}

.story-list-single {
    border-radius: 30px;
    border-radius: 30px;
    padding: 20px 25px;
    background: rgba(29, 27, 65, 0.63);
    margin-top: 12px;
    border: 1px solid rgba(29, 27, 65, 0.63);
}

.story-list-single.active {
    border: 1px solid #8fa2ff;
}

.story-list-single:first-child {
    margin-top: 0;
}

.story-list-single p {
    font-size: 13px;
    font-weight: 300;
}

.left-scroll {
    height: 628px;
    overflow-x: hidden;
    overflow-y: auto;
    margin-right: -15px;
    padding-right: 15px;
}

/* .left-scroll::-webkit-scrollbar {
    width: 5px;
}

.left-scroll::-webkit-scrollbar-track {
    background: #b6c1ff;
    border-radius: 50px;
}

.left-scroll::-webkit-scrollbar-thumb {
    background: #8fa2ff;
    border-radius: 50px;
} */

.font-block h5 {
    font-size: 14px;
    font-weight: 500;
}

.font-block h6 {
    font-size: 11px;
    font-weight: 300;
}

.inp-small {
    width: 100%;
    border-radius: 6px;
    background: rgba(29, 27, 65, 0.63);
    border: none;
    color: var(--white);
    padding: 10px 5px;
    font-size: 13px;
}

.type-div {
    display: inline-flex;
    align-items: center;
    border-radius: 40px;
    background: rgba(29, 27, 65, 0.63);
    padding: 0 20px;
    height: 40px;
    margin-top: 8px;
    justify-content: space-between;
    width: 100%;
}

.type-div span {
    font-size: 20px;
    display: block;
    margin-left: 15px;
    height: 100%;
    display: flex;
    align-items: center;
}

.type-div span.active {
    color: var(--secondary);
    font-weight: 600;
}

.type-div span:first-child {
    margin-left: 0;
}

textarea.inp-small {
    height: 165px;
    padding-top: 20px;
}

.font-size {
    height: 40px;
    border-radius: 40px;
    background: rgba(29, 27, 65, 0.63);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
}

.row.sm {
    margin-left: -4px;
    margin-right: -4px;
}

.row.sm [class*="col-"] {
    padding-left: 4px;
    padding-right: 4px;
}

.inp-small.center {
    text-align: center;
    padding-left: 0;
    padding-right: 0;
}

.color-indicator {
    display: flex;
    align-items: center;
    height: 40px;
    border-radius: 40px;
    background: rgba(29, 27, 65, 0.63);
    padding: 0 20px;
}

.color-box {
    border-radius: 5px;
    background: #18185e;
    width: 23px;
    height: 23px;
}

.color-val {
    font-size: 13px;
    padding-left: 10px;
}

.color-group {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 8px;
}

.color-group span {
    width: 24px;
    height: 24px;
    background: #18185e;
    margin: 6px 6px 0 0;
}

.opt-select {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.switch {
    position: relative;
    display: inline-block;
    width: 44px;
    height: 24px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.switch .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(29, 27, 65, 0.63);
    border: 1px solid #8fa2ff;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 50px;
}

.switch .slider:before {
    position: absolute;
    content: "";
    height: 14px;
    width: 14px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    background-color: #fff;
    border-radius: 50px;
}

.switch input:checked+.slider:before {
    -webkit-transform: translateX(20px);
    -ms-transform: translateX(20px);
    transform: translateX(20px);
    background-color: #8fa2ff;
}

.switch .slider.round {
    border-radius: 34px;
}

.switch .slider.round:before {
    border-radius: 50%;
}

.slidecontainer {
    width: calc(100% - 80px);
}

.slidecontainer input {
    width: 100%;
}

.sliderN {
    -webkit-appearance: none;
    appearance: none;
    width: 100%;
    height: 3px;
    background: #d3d3d3;
    outline: none;
    -webkit-transition: 0.2s;
    transition: opacity 0.2s;
    border-radius: 50px;
}

.sliderN::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 15px;
    height: 15px;
    background: var(--primary);
    border-radius: 100%;
    cursor: pointer;
}

.sliderN::-moz-range-thumb {
    width: 15px;
    height: 15px;
    background: var(--primary);
    cursor: pointer;
    border-radius: 100%;
}

.innerTab {
    margin-top: 20px;
    margin-bottom: 10px;
}

.innerTab.full {
    justify-content: space-between;
}

.innerTab.full2 {
    justify-content: center;
}

.innerTab .nav-link {
    padding: 0;
    color: #d9d9d9;
    font-weight: 300;
    font-size: 14px;
}

.innerTab .nav-link.active {
    color: var(--white);
    font-weight: 600;
}

.innerTab .nav-item {
    margin-left: 20px;
}

.innerTab .nav-item:first-child {
    margin-left: 0;
}

.photo-single {
    height: 100px;
    position: relative;
    margin-top: 10px;
    overflow: hidden;
    display: flex;
    justify-content: center;
}

.photo-single img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.photo-scroll {
    max-height: 480px;
    overflow-x: hidden;
    overflow-y: auto;
    margin-right: -15px;
    padding-right: 15px;
}

/* .photo-scroll::-webkit-scrollbar {
    width: 5px;
}

.photo-scroll::-webkit-scrollbar-track {
    background: rgba(255, 255, 255);
    border-radius: 50px;
}

.photo-scroll::-webkit-scrollbar-thumb {
    background: var(--secondary);
    border-radius: 50px;
} */

.video-duration {
    position: absolute;
    left: 15px;
    bottom: 15px;
    font-size: 14px;
}

.upload {
    border-radius: 30px;
    height: 165px;
    background: rgba(29, 27, 65, 0.63);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
    text-align: center;
    border: 1px dashed rgb(49 47 110);
}

.upload svg {
    font-size: 30px;
}

.upload p {
    font-size: 13px;
    padding: 10px 0 0 0;
}

.upload input {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    opacity: 0;
}

.innerTab.alt {
    border-top: 1px solid var(--white);
    padding-top: 15px;
}

.audio-single {
    border-radius: 30px;
    background: var(--primary);
    height: 35px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 15px;
    margin-bottom: 10px;
}

.audio-left {
    display: flex;
    align-items: center;
}

.audio-left p {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    -webkit-line-clamp: 1;
    text-overflow: ellipsis;
    font-size: 14px;
    padding-left: 8px;
}

.infoTxt {
    font-size: 14px;
}

.audio-list {
    padding-top: 10px;
}

.position ul {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -4px;
}

.position ul li {
    padding: 0 4px;
}

.position-single {
    width: 33px;
    border-radius: 6px;
    background: rgba(29, 27, 65, 0.63);
    border: 1px solid rgba(29, 27, 65, 0.63);
    height: 33px;
    margin-top: 8px;
}

.position-single.active {
    border: 2px solid var(--secondary);
}

.audio-scroll {
    height: 616px;
    overflow-x: hidden;
    overflow-y: auto;
    margin-right: -15px;
    padding-right: 15px;
}

/* .audio-scroll::-webkit-scrollbar {
    width: 5px;
}

.audio-scroll::-webkit-scrollbar-track {
    background: #b6c1ff !important;
    border-radius: 50px;
}

.audio-scroll::-webkit-scrollbar-thumb {
    background: #8fa2ff !important;
    border-radius: 50px;
} */

.audio-scroll-2 {
    height: 617px;
    overflow-x: hidden;
    overflow-y: auto;
    margin-right: -15px;
    padding-right: 15px;
}

/* .audio-scroll-2::-webkit-scrollbar {
    width: 5px;
}

.audio-scroll-2::-webkit-scrollbar-track {
    background: #b6c1ff !important;
    border-radius: 50px;
}

.audio-scroll-2::-webkit-scrollbar-thumb {
    background: #8fa2ff !important;
    border-radius: 50px;
} */

.position {
    width: 150px;
}

.editor-right {
    width: calc(100% - 405px);
    padding-left: 30px;
}

.editable {
    height: auto;
    border-radius: 43px;
    margin-top: 30px;
    /* background: var(--dark-blue); */
}

.editor-controller {
    margin-top: 20px;
    margin-bottom: 20px;
    max-height: 290px;
    border-radius: 43px;
    background: var(--dark-blue);
    padding: 20px;
}

.editor-right {
    /* height: calc(100vh - 325px); */
    overflow-x: hidden;
    overflow-y: auto;
    padding-right: 15px;
}

/* .editor-right::-webkit-scrollbar {
    width: 5px;
}

.editor-right::-webkit-scrollbar-track {
    background: rgba(255, 255, 255, 0.3);
    border-radius: 50px;
}

.editor-right::-webkit-scrollbar-thumb {
    background: #00d0b0;
    border-radius: 50px;
} */

.control-bar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-radius: 100px;
    background: rgba(29, 27, 65, 0.3);
    height: 36px;
    padding: 0 30px;
}

.control-bar-left ul {
    display: flex;
    align-items: center;
}

.control-bar-left ul li {
    font-size: 18px;
    margin-left: 20px;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.control-bar-left ul li:first-child {
    margin-left: 0;
}

.control-bar-left ul li span {
    font-size: 12px;
    font-weight: 300;
    padding-left: 5px;
}

.slidecontainer.alt {
    width: 100px;
    transform: translateY(-5px);
}

.timeline {
    padding: 30px 0 0 0;
}

.timeline ul {
    display: flex;
    align-items: center;
}

.timeline ul li {
    margin-left: 10px;
}

.timeline ul li:first-child {
    margin-left: 0;
}

.timeline-single {
    width: 148px;
    height: 82px;
    border-radius: 16px;
}

.timeline-single img {
    width: 148px;
    height: 82px;
    object-fit: cover;
    border-radius: 16px;
}

.add-timeline {
    width: 146px;
    height: 80px;
    border-radius: 16px;
    border: 1px solid #e0e0e0;
    background: rgba(29, 27, 65, 0.3);
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

.add-timeline svg {
    font-size: 24px;
}

.timeline-wrap {
    padding: 0 30px;
    position: relative;
    overflow-y: hidden;
    overflow-x: auto;
}

.audio-timeline {
    background: #7a5986;
    height: 36px;
    border-radius: 12px;
    margin-top: 15px;
    padding: 0 30px;
    padding-left: 50px;
    position: relative;
}

.audio-timeline-in {
    background: url("../src/assets/images/audio.png") center center repeat-x;
    height: 36px;
}

.audio-timeline span {
    position: absolute;
    left: 15px;
    top: 50%;
    transform: translateY(-50%);
    display: block;
    font-size: 20px;
}

.audio-timeline.alt {
    background: #5c89bd;
}

.stick {
    position: absolute;
    left: 20%;
    top: 0;
}

.help-container {
    max-width: 1138px;
    margin: 0 auto;
}

.help-list {
    margin-top: 30px;
}

.help-container .project-search {
    margin-top: 80px;
}

.help-single {
    text-align: center;
    margin-top: 30px;
    padding: 0 25px;
    border: 1px solid rgb(49 47 110);
    border-radius: 15px;
    padding: 30px;
    height: 213px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    flex-direction: column;
}

.help-single h2 {
    font-size: 17px;
    padding-top: 25px;
}

.help-single p {
    font-size: 14px;
    font-weight: 300;
    padding-top: 10px;
    opacity: 0.8;
}

.help-txt {
    text-align: center;
    max-width: 710px;
    margin: 0 auto;
    padding: 100px 0;
}

.help-txt h2 {
    font-size: 20px;
    font-weight: 500;
}

.help-txt p {
    font-size: 14px;
    opacity: 0.8;
    padding-top: 15px;
    font-weight: 300;
}

.help-txt .theme-btn {
    min-width: 374px;
}

.account-wrap {
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    align-items: flex-start;
    padding-top: 30px;
}

.account-nav {
    width: 230px;
}

.account-nav ul li {
    margin-top: 10px;
}

.account-nav ul li:first-child {
    margin-top: 0;
}

.account-nav ul li a {
    display: flex;
    align-items: center;
    color: var(--white);
    height: 38px;
    border-radius: 50px;
    padding: 0 20px;
}

.account-nav ul li a svg {
    font-size: 20px;
    margin-right: 10px;
}

.account-nav ul li a.active {
    background: rgb(143 157 255 / 28%);
    color: #97a8ff;
}

.account-wrap.large {
    max-width: 1400px;
}

.account-wrap.large .account-nav {
    width: 350px;
}

.account-wrap.large .account-cont {
    width: calc(100% - 350px);
}

.account-nav .nav-item {
    margin-top: 10px;
}

.account-nav .nav-item:first-child {
    margin-top: 0;
}

.account-nav .nav-link {
    display: flex;
    align-items: center;
    color: var(--white);
    height: 38px;
    border-radius: 50px;
    padding: 0 15px;
}

.account-nav .nav-link.active {
    background: rgb(143 157 255 / 28%);
    color: #97a8ff !important;
}

.account-cont {
    width: calc(100% - 230px);
    padding-left: 70px;
}

.account-block {
    border-radius: 30px;
    background: #d7d5ff08;
    padding: 20px 40px;
    margin-top: 30px;
}

.account-block.alt {
    margin-top: 0;
    min-height: 100%;
    min-height: 660px;
}

h2.account-title {
    font-size: 24px;
    font-weight: 600;
}

.account-block.sm {
    padding: 40px;
}

.account-block.first {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.account-profile-img {
    width: 127px;
    height: 127px;
    border-radius: 100%;
    margin: 0 auto;
    position: relative;
}

.account-profile-img img {
    width: 127px;
    height: 127px;
    object-fit: cover;
    border-radius: 100%;
}

.account-profile {
    text-align: center;
}

.account-profile h5 {
    padding-top: 25px;
}

.profile-img-edit {
    width: 35px;
    height: 35px;
    background: linear-gradient(90deg,
            var(--primary) 0.08%,
            var(--secondary) 101.7%);
    position: absolute;
    right: 0;
    bottom: 0;
    border-radius: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--white);
    font-size: 20px;
}

.profile-img-edit input {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    opacity: 0;
}

.privacy-block {
    border-bottom: 1px solid rgba(255, 255, 255, 0.5);
    padding: 40px 0;
}

.privacy-block:first-child {
    padding-top: 0;
}

.privacy-block:last-child {
    padding-bottom: 0;
    border: none;
}

.privacy-block h5 {
    font-weight: 600;
}

.privacy-block p {
    font-size: 14px;
    font-weight: 300;
    opacity: 0.9;
    padding: 15px 0 0 0;
}

.theme-table {
    margin: 0;
}

.theme-table tr th {
    font-weight: 300;
    padding: 0 0 15px 0;
}

.theme-table tr td {
    font-size: 13px;
    font-weight: 300;
    opacity: 0.8;
    padding: 10px 0;
    vertical-align: middle;
}

.table> :not(caption)>*>* {
    padding: 0;
    background: none;
    color: #fff;
}

.table-icon {
    font-size: 20px;
}

.welcome-cont {
    text-align: center;
}

.no-cont {
    text-align: center;
    padding: 80px 0;
}

.no-cont h4 {
    font-weight: 600;
}

.login-footer {
    position: fixed;
    width: 100%;
    left: 0;
    bottom: 0;
    z-index: 2;
    background: var(--body);
}

.login-header .site-header {
    background: none;
    border: none;
}

.training-img {
    padding: 5px;
    background: #00d0b0;
    border-radius: 15px;
    margin-top: 15px;
}

.training-img img {
    width: 100%;
    border-radius: 10px;
}

.training-block {
    margin-top: 40px;
}

.training-block:first-child {
    margin-top: 0;
}

/* .video-project-wrap.alt .col-lg-3 {
    width: 25%;
} */

.video-project-img video,
.video-project-img iframe {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.widegt_top_wrap .widget_img_wrapper {
    display: inline-block;
}

.widegt_top_wrap .widget_img_wrapper img {
    object-fit: scale-down;
}

.widget_img_wrapper {
    width: auto;
    display: flex;
    justify-content: center;
}

.help-icon img {
    width: 50px;
}

.theme-btn.w-205 {
    min-width: 205px;
}

.trans-name {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.int-logo img {
    max-height: 60px;
}

.single-block {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.single-block h5 {
    font-size: 14px;
}

.single-block-icon {
    font-size: 20px;
}

.slidecontainer.full {
    width: 100%;
}

.sketch-picker {
    position: absolute;
    top: 1px;
    z-index: 9999;
}

.text_picker .sketch-picker {
    right: 0;
}

.color-indicator {
    position: relative;
}

.uploadList {
    margin-top: 30px;
}

.uploaded-images ul {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin: 0 -4px;
}

.uploaded-images ul li {
    padding: 0 4px;
}

.upload-image-single {
    width: 150px;
    border: 1px solid var(--primary);
    padding: 5px;
    border-radius: 10px;
}

.upload-image-single img {
    border-radius: 6px;
    width: 100%;
    height: 88px;
    object-fit: cover;
}

.uploadList .upload {
    border: 1px solid var(--primary);
}

.script-wrap {
    max-width: 1200px;
    margin: 0 auto;
}

.script-outer {
    background: var(--body);
    border-radius: 15px;
    padding: 30px;
    margin-top: 40px;
}

.script-in {
    border: 2px solid #3d3a64;
    border-radius: 15px;
}

.script-top {
    display: flex;
    align-items: center;
    height: 50px;
    border-bottom: 1px solid #3d3a64;
    padding: 0 20px;
}

.script-top p span {
    color: var(--primary);
    font-size: 18px;
    font-weight: 500;
}

.script-mid .inpx {
    border: none;
}

.script-bottom {
    display: flex;
    align-items: center;
    height: 50px;
    border-top: 2px solid #3d3a64;
    padding: 0 20px;
    justify-content: flex-end;
}

.sound-control {
    border: 1px solid var(--primary);
    border-radius: 15px;
    padding: 30px;
    margin-top: 15px;
}

.rec-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 15px;
}

.record-img {
    margin: 0 3px;
    border: 2px solid var(--primary);
    width: 50px;
    height: 50px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-size: 15px;
}

.record-img svg {
    font-size: 18px;
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(359deg);
    }
}

.spin {
    animation: spin 1000ms linear infinite;
}

.editable .editor_16X9 {
    width: 853px !important;
    height: 480px !important;
    background: none !important;
    border-radius: 0 !important;
    margin: 20px auto 0 auto;
    overflow: hidden;
    /* width: 100%; */
}

.editable .editor_9X16 {
    width: 360px !important;
    height: 640px !important;
    background: none !important;
    border-radius: 0 !important;
    margin: 0 auto;
    overflow: hidden;
}

.editable .canvasEditor-main {
    position: relative;
    width: 100%;
    height: 100%;
    overflow: hidden;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    border-radius: 10px;
    background: #000;
    padding-top: 0;
}

.editable .video-container {
    position: absolute;
    top: 0;
    height: 100%;
    width: 100%;
    overflow: hidden;
}

.editable .video-container video {
    position: absolute;
    top: 0;
    width: 100% !important;
    height: 100%;
    object-fit: contain;
    display: block;
}

.editor-bar {
    /* background: rgba(29, 27, 65) !important; */
    background: rgb(57 54 98) !important;
    height: 40px !important;
    max-width: 854px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
    border-radius: 30px;
    width: 100%;
    margin: 0 auto;
    margin-top: 18px;
}

.editor-bar-left ul {
    display: flex;
    height: 100%;
    align-items: center;
}

.editor-bar-left ul li {
    margin: 0px 5px;
}

.whiteFont {
    color: #fff;
    font-size: 20px;
}

.video-slide {
    margin-top: 5px;
    overflow: auto;
    display: flex;
    padding-bottom: 8px;
    margin-bottom: -4px;
    cursor: pointer;
}

.video-slide::-webkit-scrollbar {
    height: 5px;
}

/*
.video-slide::-webkit-scrollbar-track {
    background: #b6c1ff !important;
    border-radius: 50px;
}

.video-slide::-webkit-scrollbar-thumb {
    background: #8fa2ff !important;
    border-radius: 50px;
} */

.video-slide ul {
    display: flex;
    white-space: nowrap;
    position: relative;
    align-items: center;
    margin: 0 16px;
}

.editor_slide_bg {
    background: rgba(29, 27, 65, 0.6);
    padding: 10px 50px;
    border-radius: 10px;
    position: relative;
    display: flex;
    align-items: end;
    margin: 0px 5px;
}

.video-slide ul li .editorReel-single-li {
    display: flex;
    justify-content: center;
    align-items: center;
    /* margin-left: 79px !important; */
    margin-right: 9px;
    width: 175px;
    height: 95px;
    background: #121521;
    border: 2px solid #454545;
    border-radius: 8px;
    position: relative;
    overflow: hidden;
}

/* .video-slide ul li .editorReel-single-li {
    width: 100%;
    height: 100%;
} */

.video-slide ul li .Sub-editorReel-single-li {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 9px;
    width: 150px;
    height: 75px;
    background: #121521;
    border: 2px solid #454545;
    border-radius: 8px;
    position: relative;
    overflow: hidden;
}

.IconDel {
    position: absolute;
    top: 4px;
    right: 4px;
    width: 25px;
    height: 25px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #2c3450;
}

.micON {
    position: absolute;
    top: 4px;
    left: 4px;
}

.Video_Project_Render {
    width: 100%;
}

.Video_Project_Render .progress {
    border-radius: 0 !important;
}

.Video_Project_Render .progress .progress-bar {
    background-color: var(--primary) !important;
}

.progress-bar-text {
    color: #fff;
    font-size: 13px;
    /* display: flex;
    justify-content: center; */
    font-weight: 700;
    /* position: absolute;
    top: 2px;
    left: 20%; */
}

.progress-bar-striped {
    background-image: linear-gradient(45deg,
            var(--secondary) 25%,
            transparent 25%,
            transparent 50%,
            var(--secondary) 50%,
            var(--secondary) 75%,
            transparent 75%,
            transparent);
}

.ck.ck-editor__editable_inline {
    border: 1px solid #f2efef !important;
    border-top: none !important;
}

.ck.ck-content p {
    color: #000;
}

.up_img {
    width: 121px;
    border-radius: 10px;
    height: 82px;
    overflow: auto;
}

.public_modal.theme-modal .modal-body {
    padding: 40px 0 0 0;
}

.public_modal.theme-modal .modal-dialog {
    max-width: 900px;
}

.public_modal.theme-modal .img-wrraper img {
    height: 54px;
    object-fit: contain;
}

.public_modal.theme-modal ul {
    padding: 0 65px;
}

.public_modal.theme-modal .social_hover {
    width: 134px;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.disable {
    filter: grayscale(1);
}

.public_modal.theme-modal p {
    color: #52f195;
    font-size: 13px;
    text-align: center;
}

.public_modal.theme-modal .social_hover .icon_hover:hover p {
    color: #fff;
}

.public_modal.theme-modal .social_hover .icon_hover:hover {
    width: 115px;
    height: 115px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    border-radius: 100%;
    background: linear-gradient(90deg,
            var(--primary) 0.08%,
            var(--secondary) 101.7%);
    transition: none;
}

.public_modal.theme-modal label {
    color: #52f195 !important;
}

.public_modal.theme-modal input::placeholder {
    color: #000000 !important;
}

.public_modal.theme-modal textarea::placeholder {
    color: #000000 !important;
}

@keyframes progress-bar-stripes {
    0% {
        background-position-y: 1rem;
    }
}

.hero-banner {
    align-items: center;
    /* display: flex; */
    height: calc(100vh - 130px);
    min-height: 810px;
    position: relative;
    /* background: url("https://reeelapps-app.s3.us-west-2.amazonaws.com/Reputor_ai/Deshboard/AIReputor+bg+video-1.jpg"); */
    background-size: cover;
    background-repeat: no-repeat;
    overflow: hidden;
}

.hero-banner:after {
    background: linear-gradient(180deg, #000 0, rgba(125, 185, 232, 0));
    content: "";
}

.hero-banner-video {
    position: absolute;
    width: 100%;
    left: 0;
    top: 0;
    padding-bottom: 56.25%;
}

.hero-banner:after {
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
}

.hero-banner-video iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /* transform: translateY(-150px); */
}

/* Added Tapas */
.login-wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 100vh;
    position: relative;
    padding: 0;
    flex-direction: row;
}

.login-left {
    width: 50%;
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.login-right {
    width: 50%;
}

.login-right-main {
    max-width: 600px;
    margin: 0 auto;
    background: rgba(0, 0, 0, 0.05);
    border-radius: 20px;
    padding: 40px;
    border: 1px solid var(--primary);
}

.welcome-txt {
    max-width: 600px;
    color: var(--white);
}

.login-right-main h2 {
    font-size: 22px;
}

.login-right-main h2 span {
    color: var(--primary);
    font-weight: 600;
}

.welcome-txt h2 {
    font-weight: 700;
    font-size: 50px;
}

.welcome-txt h2 span {
    color: var(--primary);
}

.welcome-txt p {
    padding: 15px 0 0 0;
}

.welcome-txt h3 {
    padding: 25px 0 0 0;
}

.log-option a {
    color: var(--primary);
}

/* .editor-right{ display: flex; align-items: center; justify-content: center; flex-direction: column;} */
.hero-banner {
    height: 100vh;
    min-height: 900px;
}

.welcome-top h1 {
    font-size: 50px;
}

.created-by {
    margin-top: -100px !important;
    position: relative;
    z-index: 1;
}

.innerTab.full2 {
    justify-content: space-between;
}

.innerTab.full2 .nav-item {
    margin-left: 0;
}

.innerTab.full .nav-item {
    margin-left: 0;
}

h2.tabTitle {
    font-size: 14px;
    font-weight: 500;
    padding-bottom: 15px;
    color: var(--white);
}

.innerTab {
    margin: 0 !important;
}

.photo-single.alt::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: linear-gradient(to bottom,
            rgba(30, 87, 153, 0) 0%,
            rgba(0, 0, 0, 1) 100%);
}

.trans-name {
    z-index: 1;
    align-items: flex-end;
    padding-bottom: 15px;
}

.select-tile-style {
    z-index: 1;
}

.temp-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.tabepaneIn-main {
    background: #d7d5ff08;
    border-radius: 22px;
    padding: 30px;
}

.tabRight {
    padding: 50px 90px;
}

.modalVid {
    background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
        url("./assets/images/Ai_img.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    height: 600px;
}

.theme-modal.ai_img_wrap.video-modal .modal-dialog {
    max-width: 991px;
}

/* Edit Theme */
.dash-link {
    position: relative;
    z-index: 2;
    padding-left: 25%;
}

.dash-link ul li a {
    display: flex;
    align-items: center;
    border-radius: 10px;
    color: #fff;
    background: rgba(29, 27, 65, 0.5);
    border: 1px solid #8fa2ff;
    padding: 0 15px;
    height: 60px;
}

.dash-link ul li {
    margin: 15px 0;
}

.dash-link ul li a:hover {
    background: rgba(29, 27, 65, 0.8);
}

.dash-link-icon {
    width: 40px;
    height: 40px;
    background: linear-gradient(90deg,
            var(--primary) 0.08%,
            var(--secondary) 101.7%);
    border-radius: 5px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    margin-right: 15px;
    font-size: 22px;
}

.video-modal .modal-dialog {
    max-width: 68%;
}

.video-modal .modal-content {
    padding: 0;
}

.widget-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: var(--dark-blue);
    border-radius: 10px;
    border: 1px solid #4a469d;
    padding: 15px 25px;
}

.widget-top-left {
    display: flex;
    align-items: center;
    gap: 15px;
}

.searchBar {
    position: relative;
}

.searchBar input {
    background: none;
    border: 1px solid #8fa2ff;
    border-radius: 50px;
    padding: 7px 25px;
    padding-left: 40px;
    color: #fff;
}

.searchBar span {
    position: absolute;
    left: 15px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 18px;
}

.theme-toggle .dropdown-toggle {
    border: none;
    border-radius: 5px !important;
}

.theme-toggle .dropdown-toggle::after {
    display: none;
}

.theme-toggle .dropdown-menu {
    left: unset !important;
    right: 0 !important;
    background: var(--dark-blue);
    padding: 0;
    border: 1px solid #495697;
    overflow: hidden;
    z-index: 4;
    min-width: 190px;
}

.theme-toggle .dropdown-menu a {
    display: flex;
    align-items: center;
    color: #fff;
    font-size: 13px;
    padding: 8px 12px;
    gap: 8px;
}

.theme-toggle .dropdown-menu li {
    border-top: 1px solid #495697;
}

.theme-toggle .dropdown-menu li:first-child {
    border: none;
}

.theme-toggle .dropdown-menu a:hover {
    background: #8fa2ff;
}

.table-check {
    width: 20px;
    height: 20px;
    position: relative;
    margin: 0;
    padding: 0;
    transform: translateY(3px);
}

.table-check input {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    opacity: 0;
    z-index: 2;
}

.table-check .check-mark {
    display: block;
    width: 20px;
    height: 20px;
    border-radius: 2px;
    border: 1px solid #8fa2ff;
    background: none;
    position: relative;
}

.table-check .check-mark::after {
    content: "";
    position: absolute;
    left: 3px;
    top: 3px;
    width: 12px;
    height: 12px;
    background: #8fa2ff;
    border-radius: 2px;
    opacity: 0;
}

.table-check input:checked~.check-mark::after {
    opacity: 1;
}

.widget-table {
    margin: 0;
}

.widget-table tr th {
    vertical-align: middle;
    font-weight: 400;
    background: rgba(29, 27, 65, 0.9);
    padding: 14px 15px;
    border-top: 1px solid #4a469d;
    border-bottom: 1px solid #4a469d;
}

.widget-table tr th:first-child {
    border-left: 1px solid #4a469d;
}

.widget-table tr th:last-child {
    border-right: 1px solid #4a469d;
}

.widget-table tr td {
    padding: 24px 15px;
    vertical-align: middle;
    border-bottom: 1px solid #4a469d;
    text-align: start;
}

.widg-icon a {
    display: inline-block;
    font-size: 17px;
    margin: 0 5px !important;
    color: #fff;
    cursor: pointer !important;
}

.modalClose {
    position: absolute;
    right: 0;
    top: -60px;
    font-size: 20px;
    cursor: pointer;
}

.widget-style {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    min-height: calc(100vh - 270px);
}

.widget-list {
    display: flex;
    align-items: center;
    margin-top: 30px;
}

.widget-list li {
    padding: 0 10px;
}

.widget-list li span {
    background: rgba(29, 27, 65, 0.9);
    border-radius: 10px;
    display: block;
    align-items: center;
    justify-content: center;
    text-align: center;
    border: 1px solid #4a469d;
    padding: 15px;
    cursor: pointer;
    width: 160px;
}

.widget-list li span img {
    width: 90px;
}

.widget-list li.active span {
    border: 1px solid #8fa2ff;
    background: #bfcaff;
}

.widget-list li span p {
    font-size: 14px;
    padding-top: 10px;
}

.widgetModal {
    overflow: unset !important;
}

.widgetModal .modal-dialog {
    max-width: 100%;
    margin: 0;
    padding: 0;
    background: none;
    box-shadow: none;
}

.widgetModal .modal-content {
    background: none;
    box-shadow: none;
    border-radius: 0;
    min-height: 100vh;
}

.widgetModal .modal-body {
    padding: 0;
}

.widget_elements_wrapper {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
}

.widget-elements {
    min-width: 350px;
    /* position: fixed; */
    left: 4px;
    top: 4px;
    height: calc(100vh - 8px);
    overflow-x: hidden;
    overflow-y: auto;
    padding: 20px;
    background: rgba(29, 27, 65, 0.9);
    border: 1px dashed #4a469d;
    border-radius: 5px;
    z-index: 99;
}

.widget-elements-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
}

.widget-elements-title:first-child {
    margin-top: 0;
}

.widget-elements-title h6 {
    font-size: 15px;
    font-weight: 400;
}

.widget-elements.pop {
    min-width: 260px;
    left: 353px;
    z-index: 9;
}

.widget-elements {
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.widget-elements::-webkit-scrollbar {
    display: none;
}

.widget-prev-outer {
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.widget-prev-outer::-webkit-scrollbar {
    display: none;
}

.widget-prev {
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.widget-prev::-webkit-scrollbar {
    display: none;
}

.widget-elements .common-inp {
    font-size: 13px;
    padding: 10px 15px;
}

.widget-elements .input-outer {
    margin-top: 15px;
    position: relative;
}

.input-outer label {
    font-size: 13px;
}

.widget-elements textarea.common-inp {
    height: 102px;
}

.widgt-link a {
    color: #8fa2ff;
}

.widget-elements hr {
    border-top: 1px dashed #322f73 !important;
    opacity: 1 !important;
    margin-left: -20px;
    margin-right: -20px;
}

.widget-upload {
    height: 110px;
    width: 100%;
    border: 1px dashed #8fa2ff;
    border-radius: 10px;
    display: inline-flex;
    align-items: center;
    justify-content: space-between;
}

.widget-upload input {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    opacity: 0;
}

.widget-prev-outer {
    max-height: 98vh;
    max-width: 100%;
    width: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    display: flex;
    align-items: center;
    flex-direction: column;
    padding: 40px 0;
    /* padding-left: 350px; */
}

.widget-prev {
    justify-content: safe center;
    width: 422px;
    margin: 0 auto;
    padding: 30px 45px;
    background: #fff;
    border-radius: 5px;
    position: relative;
    background-position: center !important;
    background-size: contain !important;
    background-repeat: no-repeat !important;
}

.widget-prev h6 {
    color: rgb(56, 56, 56);
    font-size: 20px;
    /* font-weight: 700; */
    padding: 9px 12px;
    border: 2px dashed transparent;
    text-align: center;
}

.widget-prev p {
    line-height: 1.8;
    color: rgb(165, 165, 165);
    font-size: 13px;
    padding: 4px 12px;
    border: 2px dashed transparent;
    text-align: center;
}

.widget-prev h6:hover,
.widget-prev p:hover {
    border: 2px dashed #dcdcdc;
}

.widget-prev-button {
    /* width: 230px; */
    min-width: 215px;
    max-width: 86%;
    margin: 0 auto;
    padding-top: 10px;
}

.widget-prev-button.full {
    width: 100%;
}

.widget-prev-button ul li {
    padding: 4px;
    border: 2px dashed transparent;
    border-radius: 8px;
}

.widget-prev-button ul li:hover {
    border: 2px dashed #dcdcdc;
}

.widget-prev-button ul li button {
    display: flex;
    align-items: center;
    max-width: 100%;
    min-width: 230px;
    border: none;
    background: rgb(212, 228, 253);
    padding: 0 13px;
    border-radius: 6px;
    font-size: 13px;
    font-weight: 600;
    height: 42px;
    margin: 0 auto;
}

.widget-prev-button ul li button img {
    width: 24px;
    margin-right: 8px;
}

.widget-prev-button ul li button.submit-btn {
    background: linear-gradient(90deg,
            var(--primary) 0.08%,
            var(--secondary) 101.7%);
    color: #fff;
    text-align: center;
    align-items: center;
    justify-content: center;
}

.widget-input {
    width: 100%;
    font-size: 13px;
    font-weight: 400;
    color: rgb(165, 165, 165);
    padding: 0.375rem 0.75rem;
    line-height: 1.5;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    text-align: center;
    font-weight: 400;
}

.widget-input:focus {
    border: 1px solid #8fa2ff !important;
}

.widget-input::placeholder {
    color: rgb(165, 165, 165) !important;
}

textarea.widget-input {
    height: 80px;
    display: block;
}

.modal-arrow {
    width: 422px;
    margin: 0 auto;
    text-align: right;
    margin-bottom: 10px;
}

.modal-arrow p {
    font-size: 13px;
    transform: translateX(150px);
    padding-bottom: 20px;
}

.modal-arrow p svg {
    cursor: pointer;
}

.modal-arrow img {
    width: 48px;
}

.widget-create-button {
    width: 860px;
    margin: 0 auto;
    text-align: right;
    padding-bottom: 30px;
}

.widgetModal-close {
    position: fixed;
    right: 20px;
    top: 20px;
    font-size: 20px;
    cursor: pointer;
    background: linear-gradient(90deg,
            var(--primary) 0.08%,
            var(--secondary) 101.7%);
    border-radius: 5px;
    width: 40px;
    height: 40px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
}

.widget-prev-outer.top {
    padding-top: 0;
    padding-bottom: 0;
    margin-top: -2px;
    /* padding-left: 356px; */
}

.widget-prev-outer.top.expand {
    /* padding-left: 618px; */
}

.widget-prev-outer.top .widget-prev {
    width: 100%;
    border-radius: 0;
}

.widget-prev-outer.top .widget-prev-button {
    width: 100%;
}

.widget-prev-outer.top .widget-prev-button ul {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
}

.widget-prev-outer.top .widget-prev-button ul li {
    margin: 0 3px;
}

.widget-prev-outer.top .widget-prev h6,
.widget-prev-outer.top .widget-prev p {
    max-width: 100%;
}

.widget-prev-outer.top .widget-create-button {
    width: auto;
    position: fixed;
    right: 30px;
    bottom: 0;
}

.widget-prev-outer.top .modal-arrow {
    text-align: center;
    padding: 20px 0 0 0;
}

.widget-prev-outer.top .modal-arrow p {
    transform: none;
    padding: 30px 0 0 0;
}

.widget-prev-outer.top .modal-arrow img {
    transform: translateX(-50%) rotate(155deg);
}

/* .widgetModal-close.alt{ color: #000;} */

.widgetModal-close2 {
    position: absolute;
    right: 10px;
    top: 4px;
    color: #999999;
    font-size: 20px;
    cursor: pointer;
}

.widgetModal-close2:hover {
    color: var(--primary);
}

.widgetModal-close3 {
    position: fixed;
    left: 339px;
    top: 0px;
    color: #fff;
    font-size: 20px;
    cursor: pointer;
    background: linear-gradient(90deg,
            var(--primary) 0.08%,
            var(--secondary) 101.7%);
    border-radius: 5px;
    width: 28px;
    height: 28px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

.widget-prev-outer.right .widget-prev {
    position: fixed;
    right: 0;
    top: 0;
    height: 100vh;
    display: flex;
    align-items: center;
    flex-direction: column;
    overflow-x: hidden;
    overflow-y: auto;
    border: none;
    border-radius: 0;
    overflow-x: hidden;
    overflow-y: auto;
}

.widget-prev-outer.right .widget-create-button {
    width: auto;
    position: fixed;
    right: 450px;
    bottom: 0;
}

.widget-prev-outer.right .modal-arrow img {
    transform: rotate(-79deg);
}

.widget-prev-outer.right .modal-arrow {
    width: auto;
    position: absolute;
    right: 450px;
}

.widget-prev-outer.right .modal-arrow p {
    transform: none;
}

.txt-format {
    border: 1px solid #adadad;
    padding: 12px;
    border-radius: 10px;
    margin-top: 15px;
}

.txt-format ul {
    display: flex;
    align-self: center;
    justify-content: space-between;
}

.txt-format span {
    font-size: 14px;
    display: inline-block;
    border-radius: 5px;
    border: 1px solid var(--primary);
    padding: 3px 7px;
    cursor: pointer;
}

.txt-format span.active {
    background: var(--primary);
}

.widget-prev-outer.box .widget-prev {
    position: fixed;
    right: 20px;
    bottom: 20px;
    border-radius: 0;
    border: none;
    max-height: calc(100vh - 200px);
    overflow-x: hidden;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: safe center;
}

.widget-prev-outer.box {
    height: 98vh;
}

.widget-prev-outer.box .widget-create-button {
    width: auto;
    position: fixed;
    right: 20px;
    top: 20px;
}

.widget-prev-outer.box .modal-arrow {
    width: auto;
    position: fixed;
    bottom: 100px;
    right: 480px;
    text-align: right;
}

.widget-prev-outer.box .modal-arrow p {
    transform: none;
}

.widget-prev-outer.box .modal-arrow img {
    transform: rotate(-97deg);
}

.widget-prev-outer.top.alt .widget-prev-button ul {
    display: block;
}

.widget-prev-outer.top.alt .widget-prev-button {
    width: 600px;
    margin: 0 auto;
}

.widget-prev-outer.top.alt .widget-prev h6,
.widget-prev-outer.top.alt .widget-prev p {
    width: 600px;
    margin: 0 auto;
}

.widget-prev-button.alt {
    width: 100%;
}

.chatBot-form {
    max-width: 800px;
    margin: 0 auto;
    margin-top: 50px;
    padding: 30px;
    background: var(--dark-blue);
    border-radius: 20px;
}

.chatBot-form.full {
    max-width: 100%;
}

.lead-title {
    margin-top: 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.lead-single {
    padding: 25px;
    border: 1px solid rgb(49 47 110);
    background: rgba(29, 27, 65, 0.63);
    border-radius: 10px;
    margin-bottom: 30px;
}

.lead-single:hover {
    border: 1px solid #8fa2ff !important;
}

.lead-head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-weight: 300;
}

.lead-ratings {
    display: flex;
    align-items: center;
}

.stars svg {
    color: #ffbf00;
    margin-right: 3px;
}

.lead-img {
    border-radius: 10px;
    overflow: hidden;
    height: 320px;
    margin-top: 20px;
}

.lead-img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.lead-details {
    background: var(--dark-blue);
    padding: 15px;
    border-radius: 10px;
    margin-top: 20px;
    width: 100%;
    overflow: hidden;
}

.lead-details ul li {
    display: flex;
    align-items: center;
    margin: 10px 0 0 0;
}

.lead-details ul li:first-child {
    margin-top: 0;
}

.lead-details ul li span {
    display: flex;
    align-items: center;
    gap: 5px;
    font-size: 14px;
    width: 150px;
    font-weight: 300;
    color: #dbdaff;
}

.lead-details ul li span+span {
    width: auto;
}

.lead-details ul li a {
    color: #8fa2ff;
    font-weight: 400;
}

li.filter-opt {
    display: flex;
    align-items: center;
    gap: 10px;
    color: #fff;
    font-weight: 300;
    font-size: 12px;
    padding: 10px 10px;
}

li.filter-opt .reviewsBox {
    background: transparent;
    width: 25%;
    padding: 2px 4px;
    resize: none;
    border-radius: 2px;
    border: 1px solid #8fa2ff;
    color: var(--black);
}

.reviewBoxinput::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}

li.filter-opt .inputArrow {
    font-size: 16px;
}

.filter-opt .table-check {
    transform: none;
}

.widget-top-right {
    display: flex;
    align-items: center;
    gap: 15px;
}

.folder-accordion .accordion-button {
    box-shadow: none !important;
    border: none !important;
    border-radius: 0 !important;
    background: var(--dark-blue) !important;
    color: #fff !important;
    padding: 25px 30px;
}

.folder-accordion .accordion-button:focus {
    outline: 0 !important;
    box-shadow: none !important;
}

.folder-accordion .accordion-item {
    margin-bottom: 30px;
    border: none !important;
    background: none !important;
    border: none !important;
    border: 1px solid #4a469d !important;
    border-radius: 10px;
    overflow: hidden;
    position: relative;
}

.folder-accordion .accordion-body {
    background: none !important;
    border: none !important;
    box-shadow: none !important;
    border-top: 1px solid #4a469d !important;
    color: #fff;
}

.folder-accordion .accordion-body .widget-table tr:last-child td {
    border: none;
}

.folderLinlk {
    position: absolute;
    right: 90px;
    top: 20px;
    color: #fff;
    z-index: 3;
    display: flex;
    align-items: center;
    gap: 8px;
    font-size: 20px;
}

.folderLinlk span {
    cursor: pointer;
}

.bulkUp {
    background: var(--dark-blue);
    padding: 30px;
    border-radius: 10px;
    margin: 45px 0 0 0;
}

.bulk-wrap {
    max-width: 1200px;
    margin: 0 auto;
    margin-top: 50px;
}

.place-tag {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-top: 15px;
}

.place-tag .btn {
    border-radius: 50px;
    padding-left: 30px;
    padding-right: 30px;
}

/* Edit Theme */

.editor-left-in {
    width: 480px;
    background: var(--white);
    border-radius: 20px;
    height: auto;
    background: #393662;
    position: relative;
    overflow: hidden;
    height: 100%;
}

.editor-left-in::after {
    content: "";
    position: absolute;
    width: 330px;
    height: 100%;
    right: 0;
    top: 0;
    background: #201e55;
}

.editor-right {
    width: calc(100% - 480px);
    padding-left: 30px;
}

.editor {
    height: 422px;
    background: #eddbc5;
    border-radius: 20px;
}

.editor-tab {
    display: flex;
    justify-content: space-between;
}

.editor-tab-left {
    width: 150px;
    margin: 0;
    padding: 30px 0;
}

.editor-tab .tab-content {
    width: calc(100% - 150px);
    padding-left: 12px;
    position: relative;
    z-index: 1;
    padding: 18px;
    padding-top: 32px;
}

.editor-tab-left .nav-item {
    margin: 4px 0;
}

.editor-tab-left .nav-link {
    display: flex;
    align-items: center;
    margin: 0;
    padding: 10px 12px;
    color: #fff;
    font-size: 15px;
}

.editor-tab-left .nav-link.active {
    background: #201e55;
    color: #fff;
}

.editor-tab-left .nav-link span {
    display: inline-block;
    padding-right: 8px;
    transform: translateY(-1px);
}

.editor-tab-left .nav-link span svg {
    width: 20px;
    height: 20px;
}

.cont-search {
    position: relative;
}

.cont-search.with-icon .cont-inp {
    padding-left: 42px;
}

.cont-inp {
    width: 100%;
    border: 1px solid var(--white);
    border: 0.5px solid #dfdfdf;
    border-radius: 50px;
    padding: 9px 20px;
    font-size: 14px;
}

.cont-search span {
    position: absolute;
    left: 20px;
    top: 50%;
    transform: translateY(-50%);
    margin-top: -1px;
    color: var(--secondary);
}

.clip-cont {
    margin-top: 20px;
}

.input {
    width: 100%;
    border: 0.5px solid #48469f;
    border-radius: 5px;
    padding: 9px 20px;
    font-size: 14px;
    color: #fff;
}

.input.solid {
    background: #1a1944;
    border: 1px solid #48469f;
}

.inp-wrap label {
    font-size: 14px;
    color: var(--body-font-color);
    display: block;
    padding-bottom: 5px;
}

textarea.input {
    height: 120px;
    background: #1a1944;
}

select.input {
    height: 41px;
    padding-top: 0;
    padding-bottom: 0;
    appearance: none;
    /* background: url('../src/assets/images/down.png') right 20px center no-repeat #FFF; */
}

select.input.solid {
    /* background: url('../src/assets/images/down.png') right 20px center no-repeat #FAFAFA; */
}

.inner-tab {
    padding-top: 14px;
    height: 590px;
    overflow-y: auto;
    overflow-x: hidden;
    padding-right: 5px;
}

.inner-tab::-webkit-scrollbar {
    height: 4px;
    width: 6px;
    border-radius: 10px;
}

/* .inner-tab::-webkit-scrollbar-track {
    background: #17163a;
}

.inner-tab::-webkit-scrollbar-thumb {
    background: var(--primary);
    border-radius: 10px;
}

.inner-tab::-webkit-scrollbar-thumb:hover {
    background: var(--primary);
    border-radius: 10px;
} */

.inner-tab .tab-content {
    width: 100%;
    padding: 15px 0 0 0;
}

.inner-tab .nav {
    display: flex;
    align-items: center;
}

.inner-tab .nav-item {
    margin-left: 15px;
}

.inner-tab .nav-item:first-child {
    margin-left: 0;
}

.inner-tab .nav-link {
    padding: 0;
    font-size: 14px;
    color: var(--body-font-color);
}

.inner-tab .nav-link.active {
    color: var(--secondary);
}

.list-images ul {
    display: flex;
    align-items: flex-start;
    margin: 0 -5px;
    flex-wrap: wrap;
}

.list-images ul li {
    width: 50%;
    padding: 0 5px;
    margin-top: 10px;
}

.list-images-single {
    position: relative;
    height: 70px;
    overflow: hidden;
    border-radius: 10px;
}

.list-images-single img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.upload_scrolls {
    max-height: 385px;
    padding: 10px;
    overflow-y: auto;
    overflow-x: hidden;
}

/* .upload_scrolls::-webkit-scrollbar {
    height: 4px;
    width: 6px;
    border-radius: 10px;
}

.upload_scrolls::-webkit-scrollbar-track {
    background: #17163a;
}

.upload_scrolls::-webkit-scrollbar-thumb {
    background: var(--primary);
    border-radius: 10px;
}

.upload_scrolls::-webkit-scrollbar-thumb:hover {
    background: var(--primary);
    border-radius: 10px;
} */

.upload-box {
    height: 120px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
    border-radius: 15px;
    background: #1a1944 !important;
    text-align: center;
    border: 1px dashed #48469f !important;
    color: #fff;
}

.upload-box svg {
    font-size: 22px;
}

.upload-box p {
    font-size: 14px;
}

.upload-box input {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    opacity: 0;
    z-index: 2;
}

.upload-box.large {
    height: 250px;
}

.upload-box-large {
    color: #618fca;
}

.upload-box-large svg {
    font-size: 50px;
}

.upload-box-large p {
    font-size: 17px;
}

.font-heading ul li {
    background: #1a1944;
    border-radius: 5px;
    text-align: center;
    font-size: 14px;
    padding: 11px 5px;
    margin-top: 8px;
}

.font-heading ul li:first-child {
    margin-top: 0;
}

.box-cont {
    /* margin-top: 15px; */
}

.box-cont label {
    display: block;
    font-size: 14px;
    padding-bottom: 5px;
}

.box-cont-in {
    background: #1a1944;
    border-radius: 5px;
    padding: 15px;
}

.txt-size {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.txt-size span {
    color: #fff;
}

.txt-size span:last-child {
    font-size: 20px;
}

.slider {
    -webkit-appearance: none;
    width: 100%;
    /* height: 5px; */
    background: #fff;
    outline: none;
    opacity: 0.7;
    -webkit-transition: 0.2s;
    transition: opacity 0.2s;
    border-radius: 10px;
}

.slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 15px;
    height: 15px;
    background: var(--secondary);
    cursor: pointer;
    border-radius: 100%;
}

.slider::-moz-range-thumb {
    width: 15px;
    height: 15px;
    background: var(--secondary);
    cursor: pointer;
    border-radius: 100%;
}

.slidecontainer {
    width: calc(100% - 80px);
}

.color-select {
    display: flex;
    align-items: center;
}

.selected-color {
    width: 23px;
    height: 23px;
    border-radius: 5px;
    background: #18185e;
}

.type-settings ul li {
    display: inline-block;
    font-size: 24px;
    line-height: 18px;
    margin-left: 10px;
    cursor: pointer;
}

.type-settings ul li.active {
    color: var(--secondary);
}

.type-settings ul li:first-child {
    margin-left: 0;
}

.quote-lists ul li {
    background: var(--off-white);
    border-radius: 15px;
    font-size: 14px;
    padding: 10px;
    margin-top: 12px;
    color: var(--secondary);
}

.list-delete {
    position: absolute;
    right: 6px;
    top: 6px;
    width: 20px;
    height: 20px;
    /* background: var(--primary); */
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--white);
    font-size: 13px;
    cursor: pointer;
    opacity: 0;
}

/* 
.list-delete:hover {
    background: red;
} */

.list-images-single:hover .list-delete {
    opacity: 1;
}

.img-crop {
    position: absolute;
    left: 6px;
    top: 6px;
    width: 20px;
    height: 20px;
    background: var(--white);
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--secondary);
    font-size: 13px;
    cursor: pointer;
    opacity: 0;
    transition: all 0.5s ease;
}

.list-images-single:hover .img-crop {
    opacity: 1;
}

.duration {
    position: absolute;
    left: 8px;
    bottom: 6px;
    color: var(--white);
    font-size: 14px;
}

.audio-list ul li {
    display: flex;
    align-items: center;
    position: relative;
    background: var(--off-white);
    border-radius: 15px;
    font-size: 14px;
    padding: 8px 15px;
    margin-top: 12px;
}

.audio-list ul li:first-child {
    margin-top: 0;
}

.audio-list-left {
    width: 30px;
    text-align: center;
    font-size: 20px;
}

.audio-txt {
    width: calc(100% - 40px);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: var(--secondary);
}

.audio-dur {
    color: var(--white);
    font-size: 13px;
}

.del-audio {
    cursor: pointer;
    position: absolute;
    width: 50px;
    height: 100%;
    right: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--lighter-blue);
    color: var(--secondary);
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
    font-size: 18px;
    opacity: 0;
    transition: all 0.5s ease;
}

.audio-list ul li:hover .del-audio {
    opacity: 1;
}

.editor-settings {
    border-radius: 20px;
    background: var(--white);
    padding: 8px;
    margin-top: 10px;
}

.editor-bar {
    border-radius: 12px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: var(--off-white);
    padding: 0 15px;
}

.editor-bar>div {
    flex: 1;
}

.theme-modal.gallery-modal .modal-dialog {
    max-width: 900px;
    margin-top: 60px;
}

.image-gallery-content .image-gallery-slide .image-gallery-image {
    height: 580px;
    aspect-ratio: 1;
    width: auto;
}

.image-gallery-left-nav .image-gallery-svg,
.image-gallery-right-nav .image-gallery-svg {
    width: 39px;
}

.web-images-single img {
    cursor: pointer;
}

.theme-modal.gallery-modal .modal-content {
    padding: 0;
}

.editor-bar-left ul li {
    display: inline-block;
    cursor: pointer;
    font-size: 20px;
    margin-left: 10px;
}

.editor-bar-left ul li:first-child {
    margin-left: 0;
}

.editor-bar-left ul li:hover {
    color: var(--secondary);
}

.editor-bar-mid {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}

.editor-bar-mid span {
    padding: 0 5px;
    font-size: 22px;
    display: flex;
}

.editor-bar-right {
    text-align: right;
    font-size: 20px;
}

.image-editor {
    position: relative;
}

.video-slide {
    margin-top: 5px;
    overflow: auto;
    display: flex;
    padding: 0 0 15px 0;
}

.ck-editor__editable {
    border: 1px solid #dfdfdf !important;
    border-top: 0 !important;
    box-shadow: none !important;
}

/* .video-slide::-webkit-scrollbar {
    height: 4px;
}

.video-slide::-webkit-scrollbar-track {
    background: #f1f1f1;
}

.video-slide::-webkit-scrollbar-thumb {
    background: var(--primary);
    border-radius: 10px;
}

.video-slide::-webkit-scrollbar-thumb:hover {
    background: var(--primary);
    border-radius: 10px;
} */

/* .quote-lists ul::-webkit-scrollbar {
    width: 4px;
}

.quote-lists ul::-webkit-scrollbar-track {
    background: #f1f1f1;
}

.quote-lists ul::-webkit-scrollbar-thumb {
    background: var(--primary);
    border-radius: 10px;
}

.quote-lists ul::-webkit-scrollbar-thumb:hover {
    background: var(--primary);
    border-radius: 10px;
} */

.quote-lists ul {
    padding-right: 6px;
}

.quote-lists {
    margin-right: -15px;
}

.video-slide ul {
    display: flex;
    align-items: center;
    margin: 0 16px;
    white-space: nowrap;
}

/* .video-slide ul li {
    padding: 0 8px;
    width: 150px;
  } */

.video-slide-single {
    width: 100%;
    height: 82px;
    border-radius: 16px;
    overflow: hidden;
}

.video-slide-single img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.addSlide {
    width: 150px;
    height: 82px;
    border-radius: 16px;
    border: 1px solid #e0e0e0;
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-size: 20px;
}

.audio-bar {
    margin-top: 20px;
    border-radius: 12px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: var(--light-blue);
    padding: 0 15px;
}

.render-bar {
    margin-top: 20px;
    text-align: right;
}

.client-single {
    border-radius: 30px;
    background: var(--white);
    padding: 40px 30px;
    margin-top: 30px;
    text-align: center;
    position: relative;
}

.edit-report {
    cursor: pointer;
    font-size: 25px;
    position: absolute;
    right: 30px;
    top: 20px;
}

.client-single.alt {
    padding: 0;
    margin: 0;
    position: relative;
}

.client-single h3 {
    padding-top: 20px;
}

.client-imgN {
    width: 127px;
    height: 127px;
    border-radius: 100%;
    overflow: hidden;
    margin: 0 auto;
}

.client-img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.client-social {
    margin-top: 20px;
}

.client-social ul li {
    display: inline-block;
    margin: 0 10px;
    margin-top: 13px;
}

.client-social ul li img {
    width: 33px;
}

.client-social ul li.inactive-social img {
    filter: gray;
    -webkit-filter: grayscale(1);
    filter: grayscale(1);
    opacity: 0.5;
}

.client-btn {
    margin-top: 30px;
}

.client-btn .site-link {
    margin: 0 5px;
}

.report-date {
    position: absolute;
    right: 0;
    top: -15px;
}

.content-search .site-link {
    height: 62px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 15px;
}

.cont-box-group {
    margin-top: 30px;
}

.cont-box {
    border-radius: 30px;
    background: var(--dark-blue);
    padding: 60px;
    margin-top: 30px;
}

.profilePic {
    width: 127px;
    height: 127px;
    border-radius: 100%;
    margin: 0 auto;
    position: relative;
}

.profilePic img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 100%;
}

.profilePic-up {
    width: 44px;
    height: 44px;
    background: var(--primary);
    border-radius: 100%;
    position: absolute;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--white);
    font-size: 20px;
}

.profilePic-up input {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    opacity: 0;
    z-index: 2;
}

.common-input-wrap {
    margin-top: 30px;
}

.common-input {
    width: 100%;
    border: 1px solid var(--off-white);
    background: var(--off-white);
    border-radius: 16px;
    padding: 17px 30px;
}

textarea.common-input {
    height: 162px;
}

.common-input:focus {
    border: 1px solid var(--secondary);
}

.common-input-wrap label {
    display: block;
    padding-bottom: 8px;
}

.common-input.large {
    height: 300px;
}

select.common-input {
    appearance: none;
    /* background: url('../src/assets/images/down.png') no-repeat right 25px center var(--off-white); */
}

.image-editor {
    position: relative;
    /* margin-top: 30px; */
}

.image-editor-img {
    /* height: 490px; */
}

.image-editor-img .canvas-container {
    margin: 0 auto;
}

.image-editor-img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 20px;
}

.image-editor .editor-bar {
    /* position: absolute; */
    width: calc(100% - 60px);
    /* left: 0; */
    /* bottom: 30px; */
    /* margin: 0 30px; */
}

.bottom-btn-group {
    text-align: right;
    padding-top: 50px;
}

.text-editor-single {
    border-radius: 20px;
    background: var(--white);
    padding: 30px;
    margin-top: 30px;
}

.text-editor-single h5 {
    font-size: 20px;
}

.text-editor-single p {
    font-size: 16px;
    padding: 15px 0 0 0;
}

.text-editor-bottom {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 30px;
}

.text-editor-bottom ul li {
    display: inline-block;
    cursor: pointer;
    font-size: 20px;
    margin-left: 10px;
}

.editor-wrap.alt .editor-left {
    width: auto;
}

.editor-wrap.alt .editor-left .theme-btn {
    font-size: 13px;
    padding-top: 8px;
    padding-bottom: 8px;
}

.upload-scroll-remove,
.emoji_custom_wrapper {
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.upload-scroll-remove::-webkit-scrollbar,
.emoji_custom_wrapper::-webkit-scrollbar {
    display: none;
}

.icon-items a.text-dark {
    color: #000 !important;
}

.icon-items .emoji-custom i {
    font-size: 30px;
    cursor: pointer;
}

.title-block-in {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.emoji_custom_wrapper {
    width: 100%;
    height: 405px !important;
    overflow-y: scroll;
    overflow-x: hidden;
    background: transparent;
    padding: 10px;
}

.icon-items {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
}

.emoji-custom {
    margin-right: 10px;
    margin-bottom: 10px;
}

.add-layer .layer {
    width: 100%;
    height: 40px;
    background-color: #ffffff;
    cursor: pointer;
}

.reviewLink {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 15px;
    border: 1px solid #444199;
    padding: 15px;
    border-radius: 50px;
    background: var(--dark-blue);
}

.reviewLink label {
    padding-bottom: 4px;
}

.reviewLink .theme-btn {
    padding-top: 8px;
    padding-bottom: 8px;
    min-width: 150px;
}

.review-container {
    display: flex;
    align-items: flex-start;
    margin-top: 50px;
    align-items: center;
}

.review-left {
    width: 400px;
}

.review-box {
    background: rgba(29, 27, 65, 0.9);
    border: 1px solid #4a469d;
    padding: 30px;
    border-radius: 15px;
}

.rating-top {
    display: flex;
    align-items: center;
    gap: 10px;
    font-weight: 500;
    font-size: 14px;
}

.rating-top svg {
    color: rgb(255, 174, 0);
}

.rating-top span {
    font-weight: 300;
    font-size: 15px;
}

.rating-count {
    padding-top: 10px;
}

.rating-count li {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;
}

.rating-count li span {
    font-weight: 600;
}

.rating-count li span:last-child {
    font-weight: 400;
    font-size: 14px;
}

.rating-percentage {
    height: 7px;
    width: calc(100% - 50px);
    border-radius: 50px;
    position: relative;
    overflow: hidden;
    background: #2a2666;
}

.percentage {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    background: var(--secondary);
}

.review-right {
    width: calc(100% - 400px);
    padding-left: 50px;
}

.feedback {
    padding: 24px;
    border-radius: 10px;
    border: 1px solid var(--primary);
    margin-top: 24px;
    display: flex;
    align-items: flex-start;
    position: relative;
    overflow: hidden;
}

.feedback_ribbon {
    color: var(--white);
    position: absolute;
    top: 35px;
    transform: rotate(45deg);
    right: -54px;
    width: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: red;
}

.feedback_ribbon span {
    text-transform: capitalize;
    padding: 2px 0;
}

/* .feedback_ribbon img {
    width: 100%;
    height: 100%;
    object-fit: cover;
} */

.feedback:first-child {
    margin-top: 0;
}

.feedback h6 {
    font-weight: 700;
    padding-top: 20px;
}

.feedback-user {
    width: 100px;
}

.feedback-txt {
    width: calc(100% - 100px);
    padding-left: 50px;
}

.feedback-user-img {
    width: 100px;
    height: 100px;
    border-radius: 100px;
    overflow: hidden;
}

.feedback-user-img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.star-group {
    display: flex;
    align-items: center;
    gap: 5px;
}

.rating-top-outer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
}

.revIcon {
    display: flex;
    align-items: center;
    gap: 5px;
}

.revIcon span {
    cursor: pointer;
    font-size: 20px;
    color: var(--primary);
}

.feedback-txt p {
    color: var(--black) !important;
}

.feedback.dark {
    justify-content: space-between;
}

.dividline {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    height: 100%;
    margin-top: -13px;
}

.dividline:after {
    content: "";
    display: block;
    width: 3px;
    height: 35%;
    background: #fff;
}

.dividline::before {
    content: "";
    display: block;
    width: 3px;
    height: 33%;
    background: #fff;
}

.desktop_modal {
    display: none !important;
}


/* Changes to light theme */

.header-dash .site-header {
    background: none;
}

.header-dash .site-nav>ul>li>a.active {
    color: var(--primary);
}

.welcome-txt {
    color: var(--black);
}

.login-right-main {
    background: var(--white);
}

.inp-outer {
    background: #e0e0e0;
}

.theme-btn {
    border: 1px solid var(--primary) !important;
}

.log-option {
    color: var(--black);
}

.site-footer {
    background: linear-gradient(90deg, var(--primary) 0.08%, var(--secondary) 101.7%);
}

.footer-left p {
    color: var(--white);
    font-weight: 500;
}

.footer-left p a {
    color: blue;
}

.inp {
    color: var(--black);
}

.welcome-top {
    color: var(--white);
}

.image-block-left h5,
.webnair-right h5 {
    color: var(--primary);
}

.webnair-in {
    background: rgba(255, 255, 255, 0.3);
}

.webnair-right {
    padding-left: 30px;
}

.webnair-right p {
    font-weight: 400;
}

.dash-link ul li a {
    background: rgba(33, 197, 107, 0.6);
    border: 1px solid var(--primary);
}

.dash-link ul li a:hover {
    background: var(--primary);
}

.dash-link-icon {
    background: var(--white);
    color: var(--primary);
}

.site-nav {
    border: 1px solid var(--primary);
}

.site-nav>ul>li>a:hover {
    color: var(--primary);
}

.site-nav>ul>li>a.active {
    color: var(--charcoal);
}

.site-nav>ul>li>ul {
    background: var(--white);
    border: 1px solid var(--primary);
    border-radius: 15px;
    padding: 15px 0;
}

.site-nav>ul>li>ul>li>a {
    background: var(--white);
    border: none;
    color: var(--primary);
    font-weight: 500;
}

.site-nav>ul>li>ul>li>a:hover {
    background: none;
    color: var(--primary);
}

.site-header {
    background: linear-gradient(90deg, var(--primary) 0.08%, var(--secondary) 101.7%);
}

.site-header.fixed {
    background: linear-gradient(90deg, var(--primary) 0.08%, var(--secondary) 101.7%);
}

.site-header.fixed .site-nav>ul>li>a.active {
    color: var(--charcoal);
}

.site-nav {
    border: 1px solid var(--secondary);
}

.image-block-left p {
    font-weight: 400;
}

.profile-link .dropdown-menu {
    background: var(--white);
    border: 1px solid var(--primary);
}

.profile-link .dropdown-menu ul li a {
    color: var(--primary);
    font-weight: 500;
}

.profile-link .dropdown-menu ul li a:hover {
    color: var(--primary);
    opacity: 0.5;
}

.site-nav>ul>li>ul>li>a.inner-active {
    background: var(--primary) !important;
    color: #000;
}

.common-inp.alt {
    background: var(--white);
    color: var(--black);
    border: 1px solid #adadad;
}

.common-inp.alt:focus {
    border: 1px solid var(--primary) !important;
}

.account-nav ul li a {
    color: var(--charcoal);
    font-weight: 500;
}

.account-nav ul li a:hover {
    color: var(--primary);
}

.account-nav ul li a.active {
    background: linear-gradient(90deg, var(--primary) 0.08%, var(--secondary) 101.7%);
    color: var(--white);
}

.account-block {
    border: 1px solid var(--primary);
    padding-top: 40px;
    padding-bottom: 40px;
}

.privacy-block {
    border-bottom: 1px solid #cecece;
}

.privacy-block p {
    font-weight: 400;
}

.theme-table tr td,
.theme-table tr th {
    font-weight: 400;
}

.account-nav .nav-link {
    color: var(--charcoal);
    font-weight: 500;
}

.account-nav .nav-link:hover {
    color: var(--primary);
}

.account-nav .nav-link.active {
    background: linear-gradient(90deg, var(--primary) 0.08%, var(--secondary) 101.7%);
    color: var(--white) !important;
}

.upload {
    border: 1px dashed var(--charcoal);
    background: var(--white);
}

.tabepaneIn-main {
    padding: 0;
}

.tabepaneIn-cont h3,
.upgrade-heading h6 {
    background: linear-gradient(90deg, var(--primary) 0.08%, var(--secondary) 101.7%) !important;
    color: var(--white);
}

.upgrade-para p {
    color: var(--charcoal) !important;
}

.tabepaneIn-cont,
.upgrade-box-content {
    background: #f9f9f9 !important;
    border: 1px solid #e7e7e7;
}

.inpx {
    background: var(--white);
    border: 1px solid #d6d6d6;
    color: var(--charcoal);
}

.inpx:focus {
    border: 1px solid var(--primary);
}

.inpx-icon {
    color: var(--primary);
}

.help-single {
    border: 1px solid var(--primary);
    color: var(--charcoal);
}

.video-project-single {
    background: var(--primary);
}

.video-project-bottom {
    color: var(--white);
}

.text-light {
    color: var(--charcoal) !important;
}

.widget-top {
    background: #f7f7f7;
    border: 1px solid #e7e7e7;
}

.searchBar input {
    border: 1px solid var(--primary);
    background: var(--white);
    color: var(--charcoal);
}

.searchBar span {
    color: var(--primary);
}

.widget-table tr th {
    background: #f7f7f7;
    color: var(--charcoal);
    border-top: 1px solid #e7e7e7;
    border-bottom: 1px solid #e7e7e7;
}

.widget-table tr th:first-child {
    border-left: 1px solid #e7e7e7;
}

.widget-table tr th:last-child {
    border-right: 1px solid #e7e7e7;
}

.widget-table tr td {
    border-bottom: 1px solid #e7e7e7;
}

.widg-icon a {
    color: var(--primary) !important;
}

.table-check .check-mark {
    border: 1px solid var(--primary);
}

.table-check .check-mark::after {
    background: var(--primary);
}

.theme-toggle .dropdown-menu {
    background: var(--white);
    border: 1px solid var(--primary);
}

.theme-toggle .dropdown-menu a {
    color: var(--charcoal);
    font-weight: 400;
}

.theme-toggle .dropdown-menu a:hover {
    background: none;
    color: var(--primary);
}

.widget-list li span {
    background: none;
    border: 1px solid var(--primary);
}

.widget-list li span p {
    font-weight: 500;
    color: var(--primary);
}

.widget-check {
    color: var(--primary) !important;
}

.modal-backdrop {
    background: rgba(255, 255, 255, 0.5) !important;
}

.widget-elements {
    background: var(--white) !important;
    border: 1px dashed #cfcfcf !important;
}

.widget-elements hr {
    border-top: 1px dashed #cfcfcf !important;
}

.widgt-link a {
    color: var(--primary);
}

.switch .slider {
    background: var(--white);
    border: 1px solid var(--primary);
}

.switch .slider:before {
    background: var(--primary);
}

.switch input:checked+.slider:before {
    background: var(--white);
}

.switch input:checked+.slider {
    background: var(--primary);
}

.widget-upload {
    border: 1px dashed #cfcfcf !important;
}

.theme-modal .modal-content {
    background: var(--white);
    border: 1px solid var(--primary) !important;
}

.theme-btn.bdr {
    color: var(--primary) !important;
}

.chatBot-form {
    background: var(--white);
    border: 1px solid #cfcfcf !important;
}

.input-outer svg {
    color: var(--primary) !important;
}

.upload-box {
    background: var(--white) !important;
    border: 1px dashed #cfcfcf !important;
    color: var(--charcoal);
}

.editor-left-in {
    border: 1px solid #cfcfcf !important;
    background: var(--primary) !important;
}

.editor-left-in::after {
    background: var(--white) !important;
}

.editor-tab-left .nav-link.active {
    background: var(--white) !important;
    color: var(--charcoal);
}

.font-heading ul li,
.box-cont-in {
    background: var(--white);
    border: 1px solid #cfcfcf !important;
}

.txt-size span {
    color: var(--charcoal);
}

.slider {
    background: #cfcfcf;
}

.photo-single {
    border: 1px solid var(--primary) !important;
}

.photo-single svg {
    color: var(--primary) !important;
}

.list-images-single {
    border: 1px solid #cfcfcf !important;
}

.inp-small {
    background: var(--white);
    border: 1px solid #cfcfcf;
    color: var(--black);
}

.shape-box .shape-item2 a,
.shape-box .shape-item a {
    background-color: var(--charcoal);
}

.shape-box .shape-item3 a {
    background-color: var(--charcoal);
}

.shape-box .shape-item2-border a,
.shape-box .shape-item-border a,
.shape-box .shape-item3-border a {
    border: 2px solid var(--charcoal);
}

.add-layer .layer {
    background-color: var(--charcoal);
}

.solid-border {
    border: 3px solid var(--charcoal);
}

.lead-single {
    background: var(--white);
    border: 1px solid #cfcfcf;
}

.lead-single:hover {
    border: 1px solid var(--primary) !important;
}

.lead-details {
    background: #f9f9f9 !important;
}

.lead-details ul li span {
    color: var(--charcoal);
    font-weight: 400;
}

.lead-details ul li.for-web {
    border-top: 1px solid #cacaca !important;
}

.card-review-cont p {
    color: var(--charcoal) !important;
}

li.filter-opt {
    color: var(--charcoal);
    font-weight: 500;
    border: none !important;
}

li.filter-opt .reviewsBox {
    border: 1px solid var(--primary) !important;
}

.folder-accordion .accordion-button {
    background: var(--primary) !important;
}

.folder-accordion .accordion-item {
    border: 1px solid var(--primary) !important;
}

.table> :not(caption)>*>* {
    color: var(--charcoal);
}

.widget-table tr td {
    color: var(--charcoal);
}

.lead_data .lead_content {
    border-bottom: 1px solid #e5e5e5 !important;
}

table .lead_data .lead_content {
    padding: 0 !important;
    padding-bottom: 10px !important;
}

.bulkUp {
    background: var(--white);
    border: 1px solid #e7e7e7;
}

.video-project-16x9,
.video-project-9x16,
.video-listing-single {
    background: #FFF !important;
    border: 1px solid #e7e7e7 !important;
}

.video-project-bottom {
    color: var(--charcoal);
}

.video-project-bottom p {
    font-weight: 500;
}

.drop-style-1 .dropdown-toggle {
    color: var(--charcoal);
}

.drop-style-1 .dropdown-menu {
    background: var(--white);
    border: 1px solid #e7e7e7;
}

.drop-style-1 .dropdown-menu ul li a {
    color: var(--charcoal);
}

.drop-style-1 .dropdown-menu ul li a:hover {
    color: var(--primary);
}

.cont-box {
    background: var(--white);
    border: 1px solid #e7e7e7;
}

.post-url-btn {
    color: var(--white) !important;
}

.common-inp {
    background: var(--white);
    border: 1px solid #e7e7e7;
    color: var(--charcoal);
}

.common-inp:focus {
    border: 1px solid var(--primary);
}

.navTab .nav-link {
    color: var(--charcoal);
}

.navTab .nav-link.active {
    background: linear-gradient(90deg, var(--primary) 0.08%, var(--secondary) 101.7%) !important;
    color: var(--white) !important;
}

.site-wrap {
    padding-top: 130px;
}

.account-wrap {
    padding-top: 0;
}

.editor-left-cont {
    background: var(--white);
    border: 1px solid #e7e7e7;
}

h2.tabTitle {
    color: var(--charcoal);
}

.story-list-single {
    background: #f7f7f7;
    border: 1px solid #e7e7e7;
}

.story-list-single p {
    font-weight: 400;
}

.story-list-single.active {
    border: 1px solid var(--primary) !important;
}

.color-indicator,
.font-size,
.type-div {
    background: var(--white);
    border: 1px solid #e7e7e7;
}

.color-box {
    border: 1px solid #e7e7e7;
}

.innerTab .nav-link {
    color: #898989;
}

.innerTab .nav-link.active {
    color: var(--charcoal);
}

.input.solid {
    background: var(--white);
    border: 1px solid #e7e7e7;
}

.editor-controller {
    background: var(--white);
    border: 1px solid #e7e7e7;
}

.active-tile-border {
    border: 2px solid var(--primary) !important;
}

.micON svg,
.IconDel svg,
.transition-wrapper svg {
    color: var(--primary) !important;
}

.editor_slide_bg {
    background: #fcfcfc;
    border: 1px solid #e7e7e7;
}

.IconDel {
    background: var(--primary) !important;
}

.IconDel svg {
    color: var(--white) !important;
}

.editor-bar {
    background: var(--primary) !important;
}

.review_box {
    border: 1px solid var(--primary) !important;
}

.reviewLink {
    border-radius: 10px;
    background: #fcfcfc;
    border: 1px solid #e7e7e7;
}

/* .dropdown button{ background: linear-gradient(90deg, var(--primary) 0.08%, var(--secondary) 101.7%) !important;} */
.review-box {
    background: #fcfcfc;
    border: 1px solid #e7e7e7;
}

.rating-percentage {
    background: var(--primary);
}

.integration-single,
.connection-single {
    border: 1px solid var(--charcoal);
}

.connection-single:hover {
    border: 1px solid var(--primary);
}

::-webkit-scrollbar {
    width: 5px !important;
}

::-webkit-scrollbar-track {
    background: #f3f3f3 !important;
    border-radius: 50px;
}

::-webkit-scrollbar-thumb {
    background: var(--primary) !important;
    border-radius: 50px;
}

/* Changes to light theme */


@media (min-width: 1600.98px) {
    .desktop_modal_bs .modal-content {
        max-width: 1277px;
        max-height: 820px;
    }
}

@media (max-width: 1600.98px) {
    .desktop_modal_bs {
        display: none !important;
    }

    .desktop_modal {
        display: block !important;
    }
}

@media (max-width: 1240px) {
    .widget_elements_wrapper {
        min-width: 100vw;
        height: 100vh;
        overflow-x: auto;
        overflow-y: hidden;
    }

    .widget-prev-outer {
        max-height: 98vh;
        min-width: 100vw;
    }
}

@media (max-width: 1199px) {
    .login-wrap {
        padding: 0 50px;
    }

    .welcome-txt h2 {
        font-size: 40px;
    }
}

@media (max-width: 1023px) {
    .login-wrap {
        flex-wrap: wrap;
    }

    .login-left {
        width: 100%;
        height: auto;
        min-height: 20px;
        padding-bottom: 30px;
        text-align: center;
    }

    .login-right {
        width: 100%;
    }

    .login-wrap {
        justify-content: center;
        flex-direction: column;
    }

    .welcome-txt {
        width: 100%;
    }
}

@media (max-width: 991.95px) {
    .dividline {
        display: flex;
        justify-content: center;
        flex-direction: row;
        align-items: center;
        height: 100%;
        margin-top: 24px;
    }

    .dividline:after {
        content: "";
        display: block;
        width: 100%;
        height: 3px;
        background: #fff;
        margin-left: 8px;
    }

    .dividline::before {
        content: "";
        display: block;
        width: 100%;
        height: 3px;
        background: #fff;
        margin-right: 8px;
    }
}

@media (max-width: 895.95px) {
    .widget-top {
        flex-wrap: wrap;
        justify-content: center;
    }

    .widget-top-left {
        margin: 20px 0;
        flex-wrap: wrap;
        justify-content: center;
    }

    .widget-prev-outer {
        max-height: 98vh;
        min-width: 130vw;
    }
}

@media (max-width: 767px) {
    .login-wrap {
        padding: 0 15px;
    }

    .login-right-main {
        padding: 20px;
    }

    .login-footer {
        position: static;
    }

    .login-header .site-header {
        position: static;
    }
}

@media (max-width: 695.95px) {
    .widget-prev-outer {
        max-height: 98vh;
        min-width: 190vw;
    }

    .modal-backdrop {
        height: 124vh;
        width: 130vw;
    }
}

/* Added Tapas */

@media (min-width: 1951px) {
    .editor-controller {
        margin-top: 26px;
    }

    .editor-right {
        height: calc(100vh - 325px);
    }
}

@media (max-width: 1700px) {

    .header-in,
    .created-by,
    .video-project,
    .webnair,
    .site-footer,
    .site-container {
        padding-left: 50px;
        padding-right: 50px;
    }

    .webnair {
        padding: 130px 0 45px 0;
    }

    .site-container.for-editor {
        max-width: 100%;
        padding-left: 20px;
        padding-right: 20px;
    }

    .control-bar-left ul li {
        margin-left: 12px;
    }

    .video-project-img {
        height: 300px;
    }
}

@media (max-width: 1599.95px) {
    .webnair {
        background-size: cover;
    }

    .editor-controller {
        margin-top: 20px;
    }

    /* .video-project-16x9 {
        height: 270px;
    }

    .video-project-img_16x9 {
        height: 196px;

    } */

    .webnair-in {
        max-width: 1250px;
        padding: 50px 0;
    }

    .image-block {
        max-width: 1050px;
    }

    .image-block-left {
        padding-right: 0;
    }

    .image-block-single:nth-child(even) .image-block-left {
        padding-left: 0;
    }

    .template-list ul li {
        width: 20%;
    }

    .account-block {
        padding: 50px;
    }

    .account-wrap {
        max-width: 100%;
    }

    .video-project-img {
        height: 300px;
    }

    .hero-banner {
        /* height: calc(100vh - 208px); */
        height: 79vh;
    }

    /* .video-project-img_9x16 {
        border-radius: 20px 20px 0px 0px;
        overflow: hidden;
        max-height: 481px;
        height: 100%;
    } */
}

@media (max-width: 1366.97px) {
    /* .video-project-16x9 {
        height: 228px;
    }

    .video-project-img_16x9 {
        height: 157px;

    } */
    /* .editable .editor_16X9 {
        transform: scale(0.8);
    } */

    .editor-right {
        padding-left: 0;
    }

    /* .editor-bar {
        transform: scale(0.9);
        margin: 0 0 0 100px;

    } */
}

@media (max-width: 1300px) {
    /* .editor-bar {
        margin: 0 0 0 0;
        max-width: 100%;
        width: 100%;
        transform: scale(1);

    } */

    .welcome-video {
        max-width: 1100px;
    }

    .welcome-top h1 {
        font-size: 50px;
    }

    .create-details {
        padding: 0 30px;
    }

    .create-details p {
        font-size: 13px;
    }

    .create-details h3 {
        font-size: 19px;
    }

    .create-details h4 {
        font-size: 46px;
    }

    .create-details-bottom span {
        font-size: 36px;
    }

    .webnair-in {
        max-width: 1130px;
    }

    .webnair-main {
        max-width: 1000px;
    }

    .footer-left p {
        font-size: 14px;
    }

    .footer-right ul li a {
        font-size: 14px;
    }

    .footer-right ul li {
        margin-left: 10px;
    }
}

@media (max-width: 1199px) {

    .header-in,
    .created-by,
    .video-project,
    .webnair,
    .site-footer,
    .site-container {
        padding-left: 30px;
        padding-right: 30px;
    }

    .editor-left {
        margin: 0 auto;
    }

    /* .video-project-16x9 {
        height: 302px;
    }

    .video-project-img_16x9 {
        height: 230px;

    } */

    .welcome-video {
        max-width: 900px;
    }

    /* .video-project-wrap .col-lg-3 {
        width: 33.33%;
    } */

    .image-block {
        max-width: 900px;
    }

    .footer-in {
        flex-wrap: wrap;
        justify-content: center;
    }

    .footer-left {
        margin: 7px 0;
    }

    .footer-left,
    .footer-right {
        align-self: stretch;
        width: 100%;
        text-align: center;
    }

    .footer-right {
        padding-top: 10px;
    }

    .footer-right ul {
        justify-content: center;
    }

    .integration ul li {
        width: 25%;
    }

    .connection-single {
        /* padding: 0 25px; */
    }

    .template-list ul li {
        width: 25%;
    }

    .template-img {
        height: 400px;
        width: 225px;
    }

    .editor-wrap {
        flex-wrap: wrap;
    }

    .editor-left {
        align-self: stretch;
        width: 100%;
    }

    .editor-right {
        padding: 0;
        margin-top: 40px;
        align-self: stretch;
        width: 100%;
    }

    .site-nav>ul {
        padding: 0 20px;
    }

    .site-nav>ul>li {
        margin: 0 8px;
    }

    .site-nav>ul>li>a {
        font-size: 12px;
    }

    .review-left {
        width: 290px;
    }

    .review-right {
        width: calc(100% - 290px);
        padding-left: 30px;
    }

    .feedback-txt {
        padding-left: 0;
    }

    .feedback-user-img {
        width: 70px;
        height: 70px;
    }
}

@media (max-width: 1023px) {
    .welcome-video {
        padding: 0 20px;
    }

    .welcome,
    .image-block {
        padding-left: 30px;
        padding-right: 30px;
    }

    .image-block-single:nth-child(odd) [class*="col-"] {
        order: 2;
    }

    .image-block-single:nth-child(odd) [class*="col-"]+[class*="col-"] {
        order: 1;
    }

    .image-block-right {
        text-align: center !important;
    }

    .image-block-left {
        max-width: 600px;
        margin: 0 auto;
        padding-top: 30px;
        text-align: center;
    }

    .webnair-left {
        text-align: center;
    }

    .webnair-right {
        text-align: center;
        max-width: 600px;
        margin: 0 auto;
    }

    .create-details {
        border: 1px solid #332f75;
    }

    .menu_bar {
        display: block;
    }

    .site-nav {
        position: fixed;
        width: 300px;
        height: 100vh;
        border-radius: 0;
        left: -300px;
        top: 0;
        background: var(--dark-blue);
        z-index: 5;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: all 0.5s ease;
    }

    .site-nav.act {
        left: 0;
    }

    .site-nav ul {
        flex-wrap: wrap;
        flex-direction: column;
    }

    .template-list ul li {
        width: 33.33%;
    }

    .account-wrap {
        flex-wrap: wrap;
    }

    .account-nav {
        align-self: stretch;
        width: 100%;
    }

    .account-cont {
        align-self: stretch;
        width: 100%;
        padding: 50px 0 0 0;
    }

    .editable {
        transform: scale(0.8);
        width: 90vh;
        margin: 0 auto;
    }

    .editor_16X9 {
        margin-left: -40px !important;
    }

    .review-container {
        flex-wrap: wrap;
    }

    .review-left {
        width: 100%;
    }

    .review-right {
        width: 100%;
        padding: 0;
        margin-top: 30px;
    }
}

@media (max-width: 991.98px) {
    .video-project-16x9 {
        margin: 0 auto;
        margin-bottom: 20px;
    }

    .video-project-9x16 {
        margin-top: 0;
        margin-bottom: 20px;
    }
}

@media (max-width: 767px) {

    .welcome,
    .image-block {
        padding-left: 15px;
        padding-right: 15px;
    }

    .welcome-video-in {
        height: 218px;
    }

    .welcome-top h1 {
        font-size: 27px;
    }

    .welcome-top p {
        padding-left: 0;
        padding-right: 0;
    }

    .editable {
        width: 100%;
        margin: 0 auto;
    }

    .header-in,
    .created-by,
    .video-project,
    .webnair,
    .site-footer,
    .site-container {
        padding-left: 15px;
        padding-right: 15px;
    }

    .video-project-wrap .col-lg-3 {
        width: 100%;
    }

    .webnair-in {
        padding: 15px;
    }

    .webnair-right {
        padding-top: 30px;
    }

    .footer-right ul {
        flex-wrap: wrap;
    }

    .footer-right ul li:last-child {
        margin: 0;
        align-self: stretch;
        width: 100%;
        text-align: center;
        padding-top: 10px;
    }

    .webnair {
        padding-top: 60px;
        padding-bottom: 35px;
    }

    .video-project {
        margin-top: 50px;
    }

    .image-block-right {
        max-width: 230px;
        margin: 0 auto;
    }

    .logo img {
        width: 170px;
    }

    .site-header-main {
        height: 66px;
    }

    .site-wrap {
        padding-top: 100px;
    }

    .connection-single {
        /* height: 90px; */
    }

    .integration ul li {
        width: 50%;
    }

    .integration-single {
        border-radius: 10px;
        align-items: center;
        text-align: center;
    }

    .connection-single {
        border-radius: 10px;
        padding: 15px;
    }

    .site-wrap {
        padding-bottom: 50px;
    }

    .theme-modal .modal-content {
        padding: 0;
        border-radius: 5px;
    }

    .theme-modal .gallery-modal .modal-dialog {
        max-width: 900px;
    }

    .dimension {
        height: 140px;
    }

    .dimension h5 {
        font-size: 25px;
    }

    .modal-close {
        right: 10px;
        top: 10px;
    }

    .top-bar {
        flex-wrap: wrap;
    }

    .top-bar label {
        white-space: nowrap;
    }

    .top-bar-right {
        flex-wrap: wrap;
    }

    .top-bar-right .theme-btn {
        align-self: stretch;
        width: 100%;
        margin: 8px 0 0 0 !important;
    }

    textarea.large {
        height: 200px;
    }

    .theme-btn.btn-min {
        min-width: 150px;
    }

    .create-cont {
        margin-top: 0;
    }

    .template-list ul li {
        width: 50%;
    }

    .template-img {
        height: 280px;
        width: auto;
    }

    .help-txt {
        padding: 30px 0;
    }

    .help-txt .theme-btn {
        min-width: 160px;
    }

    .account-wrap {
        padding-top: 0;
    }

    .account-block {
        padding: 30px;
        border-radius: 15px;
    }

    .no-cont {
        padding: 30px 0;
    }

    .editor-left {
        flex-wrap: wrap;
    }

    .editor-left-nav {
        align-self: stretch;
        width: 100%;
    }

    .editor-left-nav .navTab {
        display: flex !important;
        align-items: center;
        justify-content: center;
    }

    .navTab .nav-link {
        font-size: 20px;
        width: auto;
        height: auto;
        padding: 15px;
        border-radius: 5px;
    }

    .navTab .nav-link.active {
        height: auto;
    }

    .editor-left-cont {
        margin-top: 30px;
        align-self: stretch;
        width: 100%;
        border-radius: 5px;
    }

    .editor-left-cont {
        padding: 15px;
    }

    .editable,
    .editor-controller {
        border-radius: 5px;
    }

    .control-bar {
        padding: 0 10px;
    }

    .control-bar {
        flex-wrap: wrap;
        height: auto;
        padding: 10px;
        border-radius: 0;
    }

    .editor-controller {
        height: auto;
        padding: 15px;
    }

    .editor-wrap {
        margin-top: 0;
    }

    .login-box {
        width: 100%;
        padding-bottom: 50px;
    }

    .review-box {
        padding: 15px;
    }

    .reviewLink {
        flex-wrap: wrap;
        border-radius: 5px;
    }

    .reviewLink .theme-btn {
        width: 100%;
    }

    .feedback {
        flex-wrap: wrap;
        padding: 15px;
    }

    .feedback-txt {
        width: 100%;
        margin-top: 20px;
    }
}

@media (max-width: 530.98px) {
    .video-project-16x9 {
        max-width: 100%;
        height: 100%;
        margin-bottom: 0;
    }

    .video-project-wrap .col-xl-4.col-lg-6 {
        margin-bottom: 25px;
    }

    .video-project-wrap .col-xl-3.col-lg-6 {
        margin-bottom: 25px;
    }

    .video-project-img_16x9 {
        max-height: 300px;
    }

    .tabRight {
        padding: 0 26px;
    }

    .tabepaneIn-main {
        padding: 5px;
    }

    .tabTitle.outreach {
        margin-top: 30px;
    }
}

.loader-center-temp1 {
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--secondary);
    height: 100%;
}

.select-tile-style {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--secondary);
    font-size: 28px;
    border: 2px solid var(--secondary);
    /* border-radius: 7px !important; */
}

.photo-single video {
    width: 100%;
    height: 100%;
    object-fit: contain !important;
    /* border-radius: 30px; */
}

.list-delete {
    position: absolute;
    right: 9px;
    top: 9px;
    width: 20px;
    height: 20px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-size: 19px;
    cursor: pointer;
    opacity: 0;
}

.photo-single:hover .list-delete {
    opacity: 1;
}

.sketch-background-ui .sketch-picker {
    width: 95% !important;
}

.active-music-file {
    border: 2px solid #f0f5ff;
}

.no-showvideotag {
    visibility: hidden;
    height: 0px;
    width: 0px;
}

.layer-border {
    border: 3px dashed var(--primary);
    padding: 2px;
    transition: none;
}

.media-loader {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #7f78ff;
    font-size: 25px;
}

/* .micON svg,
.IconDel svg,
.transition-wrapper svg {
    color: #8fa2ff !important;
} */

.logo-style {
    z-index: 2;
    max-width: 100px;
}

.watermark-op {
    opacity: 0.5;
}

.logo-top-left {
    position: absolute !important;
    top: 0;
    left: 0;
    padding: 10px;
}

.logo-top-right {
    position: absolute !important;
    top: 0;
    right: 0;
    padding: 10px;
}

.logo-bottom-right {
    position: absolute !important;
    bottom: 0;
    right: 0;
    padding: 10px;
}

.logo-bottom-left {
    position: absolute !important;
    bottom: 0;
    left: 0;
    padding: 10px;
}

.logo-top-center {
    position: absolute !important;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    padding: 10px;
}

.logo-center-left {
    position: absolute !important;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    padding: 10px;
}

.logo-center {
    position: absolute !important;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 10px;
}

.logo-center-right {
    position: absolute !important;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    padding: 10px;
}

.logo-bottom-center {
    position: absolute !important;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    padding: 10px;
}

.addReelBt {
    position: absolute;
    right: -50px;
    top: 50%;
    margin-top: -17px;
    cursor: pointer;
}

.addReelBt svg {
    font-size: 40px;
    color: #838383;
}

.addReelBt svg:hover {
    color: var(--primary);
}

.active-tile-border {
    border: 2px solid var(--secondary);
}

.deactive-tile-border {
    border: 1px solid #575757;
}

.remove_line {
    background: red;
    height: 31px;
    left: 11px;
    position: absolute;
    top: -5px;
    transform: rotate(45deg);
    width: 2px;
}

.editor-background-img {
    height: 100% !important;
    object-fit: contain;
}

.profileLoader {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}

/* remotion */

.theme-modal.xl .modal-dialog {
    max-width: 768px;
}

.theme-modal .modal-content {
    border-radius: 20px;
    border: none;
    box-shadow: none;
}

.theme-modal .modal-body {
    padding: 20px;
}

.theme-modal.remotion-preview_16X9.xl .modal-dialog {
    max-width: 1080px !important;
}

.theme-modal.remotion-preview_16X9 .modal-content {
    height: 700px !important;
    padding: 10px !important;
}

.theme-modal.remotion-preview_16X9 .modal-body {
    padding: 12px !important;
}

.theme-modal.remotion-preview_1X1 .modal-dialog {
    max-width: 715px !important;
}

.theme-modal.remotion-preview_1X1 .modal-content {
    height: 798px !important;
}

.theme-modal.remotion-preview_1X1 .modal-body {
    padding: 12px !important;
}

/* 9X16 */
.theme-modal.remotion-preview_9X16 .modal-dialog {
    max-width: 460px !important;
}

.theme-modal.remotion-preview_9X16 .modal-content {
    height: 820px !important;
}

.theme-modal.remotion-preview_9X16 .modal-body {
    padding: 12px !important;
}

.image-cancle-icon {
    position: absolute;
    top: 0px;
    right: 4px;
    color: #00d0b0;
    cursor: pointer;
}

.publish_text.form-control:focus {
    color: #000000;
    background-color: transparent;
    border-color: none !important;
    outline: 0;
    box-shadow: none;
}

.upload-image-single-narration {
    width: 300px;
    border: 1px solid var(--primary);
    padding: 5px;
    border-radius: 10px;
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.non-rendering-bar {
    background: var(--primary);
    width: 100%;
    text-align: center;
    color: #fff;
    font-size: 13px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 700;
}

.loader {
    width: 80px;
    height: 80px;
    border: 3px var(--primary) solid;
    border-radius: 50%;
    animation: rotation 1s ease-in-out infinite;
    text-align: center;
}

.loader-sec {
    height: 100vh;
    width: 100%;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    /* background-color: #ffffff; */
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
}

.loader::after {
    width: 15px;
    height: 15px;
    background-color: var(--primary);
    border-radius: 100%;
    position: absolute;
    content: "";
}

@keyframes rotation {
    0% {
        transform: rotate(0);
    }

    100% {
        transform: rotate(360deg);
    }
}

.play-btn {
    display: none;
}

.welcome-video:hover .play-btn {
    display: block;
    transition: all 5s ease;
}

.card__line {
    position: absolute;
    animation: 2s 2s linear infinite;
    /* display: none; */
}

.card__line_left {
    animation-name: to-top;
    background: linear-gradient(to bottom, #00e59f, transparent);
    left: 0;
    bottom: -100%;
    width: 0.2em;
    height: 100%;
}

.card__line_right {
    animation-name: to-bottom;
    background: linear-gradient(to bottom, transparent, #00e59f);
    right: 0;
    top: -100%;
    width: 0.2em;
    height: 100%;
}

.card__line_top {
    animation-delay: 3s;
    animation-name: to-right;
    background: linear-gradient(to right, transparent, #00e59f);
    width: 100%;
    height: 0.2em;
    top: 0;
    left: -100%;
}

.card__line_bottom {
    animation-delay: 3s;
    animation-name: to-left;
    background: linear-gradient(to right, #00e59f, transparent);
    bottom: 0;
    right: -100%;
    width: 100%;
    height: 0.2em;
}

@keyframes to-top {
    to {
        bottom: 200%;
    }
}

@keyframes to-bottom {
    to {
        top: 200%;
    }
}

@keyframes to-left {
    to {
        right: 200%;
    }
}

@keyframes to-right {
    to {
        left: 200%;
    }
}

.video_nxt_btn span {
    font-size: 100px;
    color: var(--white);
}

.video_nxt_wrapper {
    height: 100% !important;
    padding-bottom: 40px !important;
    background-color: transparent;
}

.video_nxt_btn {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    /* margin-top: 42px; */
    height: 100%;
    border-radius: 20px;
    overflow: hidden;
    /* border-right: 2px #00d0b0 solid;
    border-bottom: 2px #00d0b0 solid;
    border-top: 2px #00d0b0 solid; */
    background: linear-gradient(90deg,
            var(--primary) 0.08%,
            var(--secondary) 101.7%);
    position: relative;
    overflow: hidden;
}

.video_nxt_btn::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    transition: all 0.5s ease;
    width: 0;
    height: 100%;
    opacity: 1;
    background: #fff;
}

.video_nxt_btn:hover::before {
    width: 100%;
    opacity: 1;
}

.video_nxt_btn:hover span {
    color: var(--secondary);
    position: relative;
    z-index: 2;
}

.create-modal .modal-dialog {
    max-width: 100% !important;
}

.create-modal .created-by {
    padding: 0;
}

.zoominheader {
    background-size: cover;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -webkit-animation: zoomin 10s linear infinite;
    animation: zoomin 10s linear infinite;
    transition: all 0.5s linear;
    overflow: hidden;
}

.zoomoutheader {
    width: 100%;
    background-size: cover;
    -webkit-animation: zoomout 10s linear infinite;
    animation: zoomout 10s linear infinite;
    transition: all 0.5s linear;
    overflow: hidden;
}

.zoomleft {
    width: 100%;
    background-size: cover;
    -webkit-animation: zoomout 50s linear infinite;
    animation: zoomleft 50s linear infinite;
    transition: all 0.5s linear;
    overflow: hidden;
}

@-webkit-keyframes zoomleft {
    0% {
        transform: translateX(0%) scale(1.5);
    }

    100% {
        transform: translateX(100%) scale(1);
    }
}

@keyframes zoomleft {
    0% {
        transform: translateX(0%) scale(1.5);
    }

    100% {
        transform: translateX(100%) scale(1);
    }
}

.zoomright {
    width: 100%;
    background-size: cover;
    -webkit-animation: zoomout 50s linear infinite;
    animation: zoomleft 50s linear infinite;
    transition: all 0.5s linear;
    overflow: hidden;
}

@-webkit-keyframes zoomright {
    0% {
        transform: translateX(-0%) scale(1.5);
    }

    100% {
        transform: translateX(-100%) scale(1);
    }
}

@keyframes zoomright {
    0% {
        transform: translateX(-0%) scale(1.5);
    }

    100% {
        transform: translateX(-100%) scale(1);
    }
}

/* Zoom in Keyframes */
@-webkit-keyframes zoomin {
    0% {
        transform: scale(1);
    }

    100% {
        transform: scale(2);
    }
}

@keyframes zoomin {
    0% {
        transform: scale(1);
    }

    100% {
        transform: scale(2);
    }
}

/*End of Zoom in Keyframes */

@-webkit-keyframes zoomout {
    0% {
        transform: scale(1.5);
    }

    100% {
        transform: scale(1);
    }
}

@keyframes zoomout {
    0% {
        transform: scale(1.5);
    }

    100% {
        transform: scale(1);
    }
}

.carousel {
    margin-left: auto;
}

.tabepaneIn-cont {
    overflow: hidden;
    padding: 0 30px 30px;
    border-radius: 10px;
    background: #363c6863;
}

.tabepaneIn-cont h3 {
    background: #42406c;
    color: #fff !important;
    font-size: 15px;
    margin-bottom: 30px;
    margin-left: -30px;
    margin-right: -30px;
    padding: 15px 0 15px 30px;
}

.upgrade-para {
    padding: 10px;
}

/* .upgrade-para p {
    color: #fff !important;
    font-size: 14px;
} */

.tabepaneIn-cont-alt {
    padding-top: 40px;
}

.upgrade-content {
    padding: 20px 30px;
}

.upgrade-box-content {
    background: #363c6863;
    border-radius: 10px;
    overflow: hidden;
}

.upgrade-heading h6 {
    background-color: #42406c;
    color: #fff !important;
    padding: 10px;
}

.slide-sec-merge {
    background-color: #000;
    /* padding: 15px; */
    width: 100%;
    z-index: 9;
    height: 125px;
    display: flex;
    align-items: center;
    margin-top: 20px;
}

.slide-box {
    align-items: center;
    display: flex;
    overflow-x: auto !important;
    overflow-y: hidden;
    height: 100%;
}

.slide-box ul li {
    align-items: center;
    background-color: #101010;
    /* border: 1px solid #434343; */
    padding: 2px;
    border-radius: 10px;
    color: #9a9a9a;
    display: flex;
    font-size: 14px;
    height: 90px;
    margin: 0 10px;
    text-align: center;
    width: 150px;
    overflow: hidden;
}

.set_resolution span {
    font-family: Poppins;
    font-size: 18px;
}

.set_resolution .form-check-input {
    height: 16px;
    width: 16px;
}

.set_resolution .form-check .form-check-input {
    margin-top: 5px;
}

.set_resolution .form-check-input:checked {
    background-color: #007664;
    border-color: #007664;
}

.playButton {
    align-items: center;
    display: flex;
    height: 60px;
    justify-content: center;
    width: 100%;
    background: #000;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

.playButton .playIcon {
    font-size: 25px;
}

.swiper_text {
    width: 100%;
    overflow: hidden;
    position: relative;
    top: 20px;
}

.sketch_right {
    left: -89px !important;
}

.merge-delete {
    color: rgb(0 190 161);
    position: absolute;
    top: 5px;
    right: 3px;
}

.footer-logo a img {
    width: 150px;
}

/* .slide-box::-webkit-scrollbar {
    height: 5px;
}

.slide-box::-webkit-scrollbar-thumb {
    background: #00d0b0;
    border-radius: 50px;
}

.slide-box::-webkit-scrollbar-track {
    background: rgba(255, 255, 255, 0.3);
    border-radius: 50px;
} */

.select-social {
    width: 115px;
    height: 115px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    border-radius: 100%;
    background: linear-gradient(90deg,
            var(--primary) 0.08%,
            var(--secondary) 101.7%);
    transition: none;
}

.sad_talker_img {
    width: 120px;
    height: 120px;
    margin: 0 auto;
    margin-bottom: 10px;
}

.sad_talker_wrap label {
    margin-top: 5px;
    margin-left: 8px;
}

.sad_talker_wrap .form-check-input {
    width: 20px;
    height: 20px;
}

.sad_talker_wrap .form-check-input:checked {
    background-color: var(--secondary);
    border-color: var(--secondary);
}

.sad_talker_img img {
    width: 100%;
    height: 100%;
    border-radius: 100%;
}

.sad_talker_img video {
    width: 100%;
    height: 100%;
    border-radius: 100%;
}

.temp-prev {
    padding: 50px 0;
}

.temp-prev img {
    height: 100%;
    width: 100%;
    padding: 2px;
}

.sketch-background-ui {
    position: relative;
}

.temp-prev video {
    height: 100%;
    width: 100%;
    padding: 2px;
    /* padding: 5; */
    border-radius: 20px;
}

/* .ck.ck-toolbar {
    max-width: 99%;
} */

.fb-card .youtubeicon {
    font-size: 30px;
    left: 50%;
    position: absolute;
    top: 50%;
    -webkit-transform: translate(-60%, -30%);
    transform: translate(-60%, -30%);
}

.upgrade_modal .modalClose {
    text-align: end;
    margin-bottom: 10px;
    margin-top: 10px;
}

.upgrade_modal .modal-body {
    padding: 0px !important;
}

.upgrade_modal .modal-content {
    padding: 0 20px 20px 20px !important;
}

.Talking_Wrap {
    width: 140px;
    height: 140px;
    z-index: 8;
}

.Talking_Wrap_video {
    width: 140px;
    height: 86px;
    margin-right: 16px;
}

.Talking_Wrap_video video {
    width: 100%;
    height: auto !important;
    border-radius: 100%;
}

.Talking_Wrap img {
    width: 100%;
    height: 100%;
    border-radius: 100%;
}

/* .video_player_render {} */
.Face_narration {
    text-align: center;
}

.Face_narration_section {
    display: flex;
    justify-content: center;
    align-items: center;
    /* flex-wrap: wrap; */
}

.Face_narration h2 {
    font-size: 26px;
    color: #000;
}

.Face_narration_section span {
    font-size: 26px;
    border-bottom: 1px solid #d1f0d1;
    display: inline-block;
    position: relative;
    top: 5px;
    color: #777;
}

.Face_narration_section .Thumb img {
    width: 51px;
    transform: rotateY(180deg);
}

.Face_narration_section .Thumbs img {
    width: 51px;
    transform: rotateY(180deg) rotatex(180deg);
}

.Face_narration_do {
    padding: 10px;
    width: 550px;
    margin-right: 12px;
    overflow: hidden;
}

.Face_narration_do li,
.Face_narration_dont li {
    justify-content: flex-start;
    margin-bottom: 10px;
    margin-top: 8px;
    text-align: left !important;
    display: inline-flex;
    align-items: center;
    width: 100%;
    padding: 0 0 0 100px;
    color: #000;
}

.Face_narration_do li span,
.Face_narration_dont li span {
    font-size: 14px;
    color: #000;
    border: none;
    position: static;
}

.Face_narration_do li .green {
    color: #fff;
    margin-right: 10px;
    font-size: 31px;
    width: 40px;
    padding: 6px;
    height: 40px;
    background: green;
    border-radius: 100%;
}

.Face_narration_img {
    width: 180px;
    height: 180px;
    margin: 0 auto;
    overflow: hidden;
}

.Face_narration_img img {
    width: 100%;
    height: 100%;
    border-radius: 15px;
}

.Face_narration_dont {
    padding: 10px;
    width: 550px;
}

.Face_narration_dont li .red {
    color: #fff;
    margin-right: 10px;
    font-size: 31px;
    width: 40px;
    padding: 6px;
    height: 40px;
    background: red;
    border-radius: 100%;
}

.facelogo_position .position-single {
    margin-right: 8px;
    cursor: pointer;
}

.position-circle {
    width: 40px;
    border-radius: 6px;
    background: rgba(29, 27, 65, 0.63);
    border: 1px solid rgba(29, 27, 65, 0.63);
    height: 40px;
    margin-top: 8px;
    /* border-radius: 100%; */
}

.face_size span {
    cursor: pointer;
}

.theme-modal.ds .modal-dialog {
    max-width: 743px;
}

.theme-modal.ds .modal-dialog iframe {
    width: 100% !important;
}

.editor_siteWrap {
    min-height: 100vh !important;
    display: flex;
    align-items: center;
}

.tabLeft {
    padding: 0 90px;
}

.outreach {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.tableWrap {
    overflow: hidden;
    padding: 0;
}

.tableWrap .table> :not(caption)>*>* {
    padding: 8px !important;
}

.profileBlock {
    /* background: #1e3854; */
    border: 1px solid #45487c;
    border-radius: 10px;
}

.tableWrap .table-dark th {
    border-color: #45487c;
}

.tableWrap .table-dark th {
    font-weight: 500 !important;
    text-align: center !important;
}

.tabepaneIn-cont.g {
    padding: 30px !important;
}

.formSec {
    padding: 10px;
}

.formSec .inpField {
    background: none;
    border: none;
    padding: 0;
}

.settingModal .modal-dialog {
    max-width: 1000px !important;
}

.inpField label {
    color: #fff;
    display: block;
    font: 500 16px/16px Montserrat, sans-serif;
    font-size: 17px;
    padding-bottom: 5px;
}

.settingModal .form-group-alt .form-control {
    background: #14122f;
    border: 1px solid #707070;
    border-radius: 8px;
    color: #fff !important;
    height: 70px !important;
    padding: 0px 0 0 30px;
}

.settingModal .form-group-alt .form-control::placeholder {
    color: #fff !important;
}

.settingModal .form-switch .form-check-input {
    height: 20px;
    width: 65px;
}

.settingModal .form-check-input:checked {
    background-color: #00cbac;
    border-color: #00cbac;
}

.editor-background-img_9x16 {
    height: 100% !important;
    object-fit: cover;
    width: auto;
    margin: 0 auto;
    object-position: center;
}

.editable .video-container_9x16 {
    width: 360px !important;
    height: 640px !important;
}

.editable .video-container_9x16 video {
    object-fit: cover;
}

.widget-color-box {
    height: 100%;
    width: 50px;
    position: absolute;
    top: 0;
    right: 0;
    background: #ffffff;
    border-radius: 0px 10px 10px 0px;
    cursor: pointer;
}

.widget-check {
    position: absolute;
    top: 0;
    font-size: 21px;
    right: 3px;
    color: #8fa2ff;
}

.widget-img-box {
    height: 108px;
    width: 36%;
    position: relative;
    overflow: hidden;
    z-index: 99;
}

.widget-img-box img {
    height: 100%;
    width: 100%;
    border-radius: 10px;
    object-fit: contain;
    padding: 8px;
}

.wd_delete {
    position: absolute;
    top: 0;
    display: flex;
    justify-content: center;
    width: 100%;
    align-items: center;
    height: 100%;
}

.wd_delete_main {
    display: none;
}

.widget-img-box:hover .wd_delete_main {
    display: block;
}

.txt_align {
    background: var(--white);
    padding: 0;
    border: 1px solid var(--primary);
    overflow: hidden;
    min-width: 142px;
    border-radius: 8px;
    position: absolute;
    right: 0;
    top: 71px;
    z-index: 99;
}

.txt_align div {
    border-top: 1px solid var(--primary);
    padding: 10px;
}

.txt_arr {
    width: 20px;
    height: 20px;
    background: var(--primary);
    padding: 0;
    transform: rotate(45deg);
    position: absolute;
    left: 84%;
    top: 62px;
    border: 1px solid var(--primary);
}

.widegt_top_wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
}

.widegt_top_wrap img {
    height: 100px;
}

.image-editor-img_square {
    width: 100%;
    min-height: 474px;
    max-height: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: start;
    margin-bottom: 20px;
}

.image-editor-img_varticle {
    width: 360px;
    margin: 0 auto;
    position: relative;
}

.image-editor-img_regular {
    width: 768px;
    margin: 0 auto;
    position: relative;
}

.border-style {
    display: block;
    min-height: 50px;
    margin-bottom: 45px;
    color: var(--charcoal);
    cursor: pointer;
}

.solid-border {
    border: 3px solid var(--charcoal);
}

.dashed-border {
    border: 3px dashed var(--charcoal);
}

.dashed-border-light {
    border: 2px dashed var(--charcoal);
}

.dotted-border {
    border: 3px dotted var(--charcoal);
}

.icon-items .emoji-custom span {
    font-size: 35px;
    cursor: pointer;
}

.shape-box .shape-item .circle {
    height: 55px;
    width: 55px;
    border-radius: 50%;
    /* background-color: #fff; */
}

.shape-box .shape-item .triangle {
    /* background-color: #fff; */
    width: 61px;
    height: 53px;
    -webkit-clip-path: polygon(50% 0, 0 100%, 100% 100%);
    clip-path: polygon(50% 0, 0 100%, 100% 100%);
}

.shape-box .shape-item2 a,
.shape-box .shape-item a {
    width: 46px;
    height: 46px;
    /* background-color: #fff; */
    display: inline-block;
    margin: 10px;
    cursor: pointer;
}

.shape-box .shape-item2-border a,
.shape-box .shape-item-border a {
    width: 46px;
    height: 46px;
    /* border: 2px solid #fff; */
    display: inline-block;
    margin: 10px;
    cursor: pointer;
}

.shape-box .shape-item2-border a,
.shape-box .shape-item-border a {
    width: 46px;
    height: 46px;
    /* border: 2px solid #fff; */
    display: inline-block;
    margin: 10px;
    cursor: pointer;
}

.shape-box .shape-item2-border a {
    border-radius: 5px;
    cursor: pointer;
}

.shape-box .shape-item2 a {
    border-radius: 5px;
    cursor: pointer;
}

.shape-box .shape-item3 a {
    width: 66px;
    height: 46px;
    /* background-color: #fff; */
    display: inline-block;
    margin: 10px;
    cursor: pointer;
}

.shape-box .shape-item3-border a {
    width: 66px;
    height: 46px;
    /* border: 2px solid #fff; */
    display: inline-block;
    margin: 10px;
    cursor: pointer;
}

.shape-box .shape-item .cliped-circle {
    width: 55px;
    height: 55px;
    background-color: var(--charcoal);
    border-radius: 50%;
    position: relative;
}

.shape-box .shape-item .cliped-triangle,
.shape-box .shape-item .cliped-triangle:before {
    -webkit-clip-path: polygon(50% 0, 0 100%, 100% 100%);
    clip-path: polygon(50% 0, 0 100%, 100% 100%);
}

.shape-box .shape-item .cliped-triangle {
    background-color: var(--charcoal);
    width: 61px;
    height: 53px;
    position: relative;
}

.shape-box .shape-item .cliped-circle:before {
    content: "";
    position: absolute;
    top: 3px;
    left: 3px;
    bottom: 0;
    background-color: #fff;
    width: 49px;
    height: 49px;
    border-radius: 50%;
}

.shape-box .shape-item .cliped-triangle:before {
    content: "";
    position: absolute;
    top: 5px;
    left: 6px;
    right: 0;
    bottom: 0;
    width: 49px;
    height: 45px;
    background-color: #fff;
}

.cont-box label {
    color: var(--charcoal);
}

.detailTd {
    line-height: 20px;
    width: 220px;
}

.detailTd span {
    display: flex;
    word-break: break-all;
}

.detailTd span small {
    width: 25px;
    text-align: left;
    font-size: 16px;
}

.detailTd span small+small {
    width: calc(100% - 30px);
}

.lead-details ul li span+span {
    width: calc(100% - 150px);
}

.table-linkBtn {
    background: linear-gradient(90deg,
            var(--primary) 0.08%,
            var(--secondary) 101.7%);
    color: var(--white) !important;
    padding: 4px 15px;
    border-radius: 50px;
}

.lead-details ul li a.linkBtn {
    background: linear-gradient(90deg,
            var(--primary) 0.08%,
            var(--secondary) 101.7%);
    color: var(--white) !important;
    padding: 4px 15px;
    border-radius: 50px;
}

.lead-result,
.infinite-scroll-component {
    overflow-x: hidden !important;
    overflow-y: auto !important;
}

.lead-result {
    padding-right: 10px;
    margin-top: 30px;
}

.lead-result::-webkit-scrollbar {
    display: none;
}

/* width */
/* .lead-result::-webkit-scrollbar {
    width: 5px;
}

.lead-result::-webkit-scrollbar-track {
    background: #b2cdff;
    border-radius: 20px;
}

.lead-result::-webkit-scrollbar-thumb {
    background: var(--secondary);
    border-radius: 20px;
} */

.wd_upload_img {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin-left: 20px;
}

.widget-prev-topBtn {
    width: 54% !important;
}

.widget-prev-topBtn ul {
    display: block !important;
}

.load-more {
    text-align: center;
    padding-top: 50px;
}

.video-listing-single {
    border-radius: 20px;
    background: #100f27;
    margin-top: 30px;
    transition: all 0.5s ease;
}

.video-listing-img {
    height: 328px;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    overflow: hidden;
    position: relative;
}

.play-icon {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    color: var(--white);
    font-size: 80px;
}

.video-listing-img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: all 0.5s ease;
}

.video-listing-bottom {
    display: flex;
    align-items: center;
    padding: 20px 35px;
    justify-content: space-between;
}

.video-listing {
    padding-top: 20px;
}

.video-listing-action button {
    display: flex;
    align-items: center;
    color: var(--secondary) !important;
    outline: 0;
    box-shadow: none;
    border: none;
    padding: 0;
    font-size: 30px;
    padding-right: 0 !important;
}

.video-listing-action button:focus {
    outline: 0;
    box-shadow: none;
    border: none;
}

.video-listing-action button::after {
    display: none;
}

.video-listing-action .dropdown-menu {
    width: 160px;
    border-radius: 20px;
    background: var(--white);
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.06);
    left: inherit !important;
    right: 0 !important;
    border: none;
}

.video-listing-action .dropdown-menu ul li a {
    display: flex;
    align-items: center;
    color: #263238;
    padding: 8px 16px;
    font-size: 14px;
}

.video-listing-action .dropdown-menu ul li a:hover {
    color: var(--primary);
}

.video-listing-action .dropdown-menu ul li a span {
    display: inline-block;
    padding-right: 8px;
}

.video-listing-action .dropdown-menu ul li a svg {
    font-size: 20px;
}

.video-project-bottom {
    padding-top: 9px;
}

.video-listing-info h3 {
    font-size: 15px;
}

.fetch-loader-block {
    display: flex;
    align-items: center;
    justify-content: center;
}

.fetch-loader {
    font-size: 25px;
    color: var(--primary);
}

.lead-imgx {
    display: flex;
    align-items: center;
    gap: 10px;
}

.lead-imgx img {
    width: 30px;
}

.lead-imgx p {
    font-weight: 700;
}

.lead-details ul li.for-web {
    border-top: 1px solid #252258;
    margin: 20px 0 0 0;
    padding: 15px 0 0 0;
}

.lead-single {
    margin: 0;
    height: 100%;
}

.web-images {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 10px;
}

.web-images-single {
    width: 80px;
    height: 54px;
    border-radius: 5px;
    overflow: hidden;
    margin-top: 10px;
}

.web-images-single img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.card-review-single {
    display: flex;
    align-items: flex-start;
    margin-top: 15px;
}

.card-review-img {
    width: 50px;
    height: 50px;
    border-radius: 100%;
    overflow: hidden;
}

.card-review-img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.card-review-cont {
    width: calc(100% - 50px);
    padding-left: 30px;
}

.card-review-cont p {
    color: #dbdaff;
    font-size: 14px;
    font-weight: 300;
}

.card-review-cont h5 {
    font-size: 15px;
}

.card-review-cont .rating-top-outer {
    margin-top: 5px;
}

.business-stat {
    display: inline-block;
    border-radius: 50px;
    color: #fff;
    padding: 3px 10px;
}

.business-stat.green {
    background: green;
}

.business-stat.red {
    background: red;
}

.business-stat.orange {
    background: orange;
}

.lead-details ul li span.open-hrs {
    display: block;
}

.streame-list ul {
    display: flex;
    align-items: center;
    margin: 0 -15px;
}

.streame-list ul li {
    padding: 0 15px;
}

.streame-list-img {
    height: 100px;
    width: auto;
    border-radius: 12px;
    overflow: hidden;
}

.streame-list-img img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.streame-list-single {
    margin-top: 15px;
}

.streame-list-single h6 {
    padding: 15px 0 0 0;
    text-align: center;
}

.modal-tab .nav-link {
    color: #000000;
    padding: 0px 20px;
    position: relative;
    font-weight: bold;
    font-size: 18px;
}

.modal-tab .nav-link.active {
    color: var(--secondary);
    /* font-family: GeneralSans-Medium; */
}

.css-1nmdiq5-menu {
    color: #000;
}

.talking_heading_modal .modal-dialog {
    max-width: 992px !important;
}

.talking_heading_modal .modalClose {
    top: -31px;
}

.schedule-social-icons {
    height: 25px;
}

.post-url-btn {
    padding: 5px 9px !important;
    border-radius: 20px;
    cursor: pointer;
}

/* .react-datepicker {
    background-color: #100f27 !important;
    border: 1px solid #8fa2ff !important;
}

.react-datepicker__header {
    background-color: #100f27 !important;
    border-bottom: 1px solid #8fa2ff !important;
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
    color: #fff !important;
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
    color: #fff !important;
}

.react-datepicker__day:hover,
.react-datepicker__month-text:hover,
.react-datepicker__quarter-text:hover,
.react-datepicker__year-text:hover {
    background: #8fa2ff !important;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
    background: #8fa2ff !important;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle {
    fill: #8fa2ff !important;
    color: #8fa2ff !important;
    stroke: #8fa2ff !important;
}

.timeSelect .css-13cymwt-control,
.timeSelect .css-t3ipsp-control {
    border-radius: 10px;
    border: 1px solid rgba(255, 255, 255, 0.5) !important;
    background: rgba(29, 27, 65, 0.3) !important;
    padding: 6px 10px !important;
    color: var(--white) !important;
}

.css-qbdosj-Input {
    margin: 0 !important;
    padding: 0 !important;
    color: #fff !important;
}

.css-1dimb5e-singleValue {
    color: #fff !important;
}

.css-1nmdiq5-menu {
    border: 1px solid #8fa2ff !important;
    background: var(--dark-blue) !important;
    color: #fff !important;
    z-index: 99 !important;
}

.css-1nmdiq5-menu * {
    background: var(--dark-blue) !important;
    color: #fff !important;
}

.css-1nmdiq5-menu *:hover {
    color: #fff !important;
    background: #8fa2ff !important;
}

.react-datepicker__day.disabled {
    color: #666666 !important;
} */

.order_warp ol {
    margin: 6px 0 0 17px !important;
}

.order_warp ol li {
    color: #000000;
    padding: 0 0px;
}

.auto_reply {
    width: 100%;
    align-items: center;
}

.auto_input {
    width: 100%;
    max-width: 100%;
    height: 75px;
    display: flex;
    flex-direction: row-reverse;
    overflow: hidden;
    border: 1px solid var(--primary);
    border-radius: 5px;
}

.auto_input textarea {
    height: 100%;
    resize: none !important;
    border: none;
}

.auto_input .common-inp:focus {
    border: none !important;
}

.auto_reply_btn {
    height: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.auto_send {
    width: 150px;
    height: 60px;
}

.auto_send .theme-btn {
    width: 100%;
    height: 100%;
}

.auto_reply_btn .theme-btn {
    width: 120px;
    height: 100%;
    border-radius: 5px;
}

.auto_send_btn {
    width: 180px;
}

.card_review_text .review_read_more span {
    display: inline-block;
    font-weight: 700;
    margin-left: 10px;
    cursor: pointer;
    text-decoration: underline;
}

/* .inner-active {
    background: var(--primary)!important;
} */

.review_box {
    margin-top: 20px;
    border-radius: 30px;
    background: #d7d5ff08;
    border: 1px solid #5d5a97;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-direction: column;
    max-width: 100%;
    padding: 30px;
    margin: auto;
    margin-top: 30px;
}

.review_img {
    display: flex;
    justify-content: center;
}

.review_text_wrap {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-top: -13px;
    max-width: 100%;
    flex-wrap: wrap;
    text-align: center;
}

.review_img img {
    height: 80px;
}

.lead_data {
    display: flex;
    flex-direction: column;
}

.lead_data .lead_content small {
    margin-left: 10px;
}

.lead_data .lead_content {
    display: flex;
    border-bottom: 1px solid #fff;
    padding: 12px 0;
    margin-bottom: 10px;
}

.lead_folder_table {
    min-height: 150px;
    max-height: 600px;
    overflow: auto;
    padding: 0 20px;
}

.lead_folder_table::-webkit-scrollbar {
    display: none;
}

/* .lead_folder_table::-webkit-scrollbar-track {
    background: rgba(255, 255, 255);
    border-radius: 50px;
}

.lead_folder_table::-webkit-scrollbar-thumb {
    background: var(--secondary);
    border-radius: 50px;
} */

.review_right_warp {
    height: 546px;
    overflow-y: auto;
    padding: 0 20px;
}

.review_right_warp::-webkit-scrollbar {
    display: none;
}

/* .review_right_warp::-webkit-scrollbar-track {
    background: rgba(255, 255, 255);
    border-radius: 50px;
}

.review_right_warp::-webkit-scrollbar-thumb {
    background: var(--secondary);
    border-radius: 50px;
}

.review_right_warp::-webkit-scrollbar {
    width: 7px;
    height: 7px;
} */

.image-gallery-left-nav .image-gallery-svg,
.image-gallery-right-nav .image-gallery-svg {
    height: 70px !important;
    width: 60px;
}

.review_setting_wrap .form-check-input {
    background-color: transparent !important;
}

.review_setting_wrap .form-check-input:checked {
    background-color: var(--primary) !important;
    border-color: var(--primary);
}

.proof_modal .form-check-input:checked {
    background-color: var(--primary) !important;
    border-color: var(--primary);
}

.review_setting_wrap .form-check-input:focus {
    border-color: var(--primary);
    outline: 0;
    box-shadow: none;
}

.mT_dropdown .dropdown-menu.show {
    margin-top: 6px;
}

.mT_dropdown .dropdown-item a {
    display: block;
    text-decoration: none;
    color: var(--bs-dropdown-link-color);
}

.mT_dropdown .dropdown-item.active,
.mT_dropdown .dropdown-item:active {
    color: var(--bs-dropdown-link-active-color);
    text-decoration: none;
    background-color: var(--primary);
}

.mT_dropdown .dropdown-toggle::after {
    display: none;
}

.mT_dropdown .dropdown-item:hover {
    background: var(--primary);
    color: #fff;
}

.mT_dropdown .dropdown-item a:hover {
    color: #fff;
}

[chatgpt-sidebar] {
    display: none !important;
}

[chatgpt-sidebar-popups] {
    display: none !important;
}

[grammarly-desktop-integration] {
    display: none !important;
}

[data-lastpass-root] {
    display: none !important;
}

#EmailTags {
    padding: 0;
    overflow: hidden;
    flex-wrap: wrap;
    padding: 15px;
}

#EmailTags li {
    margin: 10px;
}

input::placeholder {
    font-size: 13px;
}

select {
    font-size: 13px;
}

#EmailTags input {
    width: 100%;
    border: none;
    background: transparent;
    color: var(--black);
}

.email_tag {
    position: relative;
    border-radius: 4px;
    border: 1px solid rgb(44, 44, 44);
    padding: 7px;
    color: white;
    background: var(--primary);
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.15);
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    transition: all 0.3s;
}

.email_tag .content {
    font-size: 13px;
}

.email_tag .badge {
    border-radius: 100%;
    width: 13px;
    height: 17px;
    display: block;
    position: absolute;
    background: var(--primary);
    border: 1px solid white;
    display: flex;
    align-items: center;
    justify-content: center;
    top: -8px;
    right: -13px;
    transition: all 0.3s;
}

.email_tag .badge i {
    font-size: 9px;
}

.ck-editor__editable {
    max-width: 100%;
    min-height: 200px;
    resize: vertical;
    max-height: 910px;
}

.spin-style {
    color: var(--primary) !important;
    font-size: 25px;
}

.trans-text {
    color: #fff !important;
}

.creator-img {
    border: 1px solid gray;
    border-radius: 5px;
}


.help-icon {
    background: linear-gradient(90deg, var(--primary) 0.08%, var(--secondary) 101.7%);
    border-radius: 10px;
    width: 80px;
    height: 80px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
}

.help-icon img {
    max-height: 40px;
    width: auto;
    max-width: 40px;
}

.video-project-single {
    background: var(--white);
    border: 1px solid #e6e6e6;
}

.connection-left {
    color: var(--charcoal) !important;
}

.webnair-left {
    background: linear-gradient(90deg, var(--primary) 0.08%, var(--secondary) 101.7%);
    border-radius: 20px;
    padding: 15px;
}

.webnair-left img {
    border-radius: 15px;
}


.textEditorBox .ck.ck-content.ck-editor__editable {
    max-height: 410px;
}

.cc-btn {
    background-color: var(--primary) !important;
}

.feedback {
    border: 1px solid var(--primary);
}

.profile-tabs-wrap {
    display: flex;
    align-items: flex-start;
}

.profile-tabs-left {
    width: 300px;
}

.profile-tabs-right {
    width: calc(100% - 300px);
    padding-left: 40px;
}

.profile-tabs-left .nav-link {
    display: flex;
    align-items: center;
    gap: 8px;
}

.profile-tabs-left .nav-link svg {
    font-size: 22px;
}

.profile-tabs-right h3 {
    display: flex;
    align-items: center;
    gap: 12px;
    font-weight: 500;
}

.tootText {
    display: inline-block;
    position: relative;
    margin-left: 6px;
}

.tootText-txt {
    position: absolute;
    bottom: 100%;
    left: 50%;
    transform: translateX(-50%);
    background: var(--charcoal);
    border: 1px solid var(--charcoal);
    color: var(--white);
    font-size: 11px;
    font-weight: 500;
    line-height: 16px;
    padding: 8px;
    border-radius: 6px;
    width: max-content;
    max-width: 200px;
    text-align: center;
    opacity: 0;
    visibility: hidden;
    transition: all 0.5s ease;
}

.tootText:hover .tootText-txt {
    opacity: 1;
    visibility: visible;
}

.profile-tabs-wrap .input-wrap label {
    position: relative;
    color: #000;
}

.label-link {
    position: absolute;
    right: 0;
    top: 0;
    font-weight: 500;
    color: var(--primary);
    cursor: pointer;
}

.openTime {
    padding-left: 50px;
    margin-top: 40px;
}

.opentime-single {
    display: flex;
    align-items: center;
    gap: 5px;
    width: 33.33%;
    margin-bottom: 15px;
}

.opentime-single .table-check {
    transform: none;
}

.opentime-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
}

.post-head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid #e4e4e4;
    margin-top: 25px;
    padding-top: 15px;
}

.post-list-single {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #e4e4e4;
    border-radius: 5px;
    padding: 15px;
    margin-top: 25px;
}

.post-info {
    display: flex;
    align-items: center;
}

.post-info-img {
    width: 80px;
    height: 70px;
    border-radius: 5px;
    overflow: hidden;
}

.post-info-img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.post-info-txt {
    padding-left: 20px;
}

.post-info-txt h5 {
    font-weight: 500;
    color: #000;
    font-size: 18px;
}

.post-info-txt h6 {
    font-size: 15px;
}

.post-info-txt p {
    font-size: 14px;
    color: #919191;
    padding: 6px 0 0 0;
}

.post-data {
    display: flex;
    align-items: center;
    gap: 25px;
}

.post-data-action {
    display: flex;
    align-items: center;
    gap: 5px;
}

.post-data-action span {
    cursor: pointer;
}

.photo-row {
    display: flex;
    align-items: center;
    gap: 10px;
    flex-wrap: wrap;
}

.photoWrap {
    display: flex;
}

.closeBox {
    display: none !important;
}

.react-datepicker-wrapper {
    display: block !important;
}

.photo-col {
    width: 200px;
    height: 150px;
    border: 1px solid #e4e4e4;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
}

.photo-col-img {
    width: 180px;
    height: 130px;
    border-radius: 8px;
    overflow: hidden;
}

.photo-col-img img {
    width: 100%;
    height: 100%;
    object-fit: cover;

}

.photo-col-del {
    cursor: pointer;
    position: absolute;
    right: 4px;
    top: 5px;
    border-radius: 5px;
    background: red;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--white);
    width: 25px;
    height: 25px;
    font-size: 19px;
}

.photo-col input[type="file"] {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    opacity: 0;
    z-index: 2;
}

.reoprtIcon {
    position: absolute;
    right: 0;
    top: -7px;
    cursor: pointer;
    font-size: 26px;
}

.create-post-wrap {
    display: flex;
    align-items: flex-start;
}

.create-post-left {
    width: calc(100% - 400px);
    padding-right: 50px;
}

.post-phone {
    width: 400px;
    height: 804px;
    background: url('../src/assets/images/phone.png') no-repeat;
    background-size: 100% auto;
}

.post-phone-area {
    padding: 60px 25px 0 25px;
}

.phone-area-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #e7e7e7;
    position: relative;
    height: 42px;
    padding: 0 20px;
}

.phone-area-top span {
    font-size: 16px;
}

.business-name {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
}

.phone-tabs {
    border-bottom: 1px solid #e7e7e7;
    padding: 0 20px;
}

.phone-tabs ul {
    white-space: nowrap;
    overflow: hidden;
}

.phone-tabs ul li a {
    display: inline-block;
    font-size: 12px;
    color: var(--charcoal);
    font-weight: 500;
}

.phone-tabs ul li {
    display: inline-block;
    padding: 15px 0;
    position: relative;
    margin-left: 30px;
}

.phone-tabs ul li:first-child {
    margin-left: 0;
}

.phone-tabs ul li.active a {
    color: #3472e1;
}

.phone-tabs ul li.active::after {
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    background: #3472e1;
    width: 100%;
    height: 4px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.phone-listings ul li {
    display: flex;
    align-items: flex-start;
    border-bottom: 1px solid #e7e7e7;
    padding: 12px 20px;
    font-weight: 500;
    position: relative;
}

.phone-listings ul li .phone-listings-icon {
    width: 30px;
    color: #3472e1;
    font-size: 20px;
    line-height: 20px;
}

.phone-listings ul li .phone-listings-text {
    width: calc(100% - 30px);
    font-size: 13px;
}

.phone-map {
    position: absolute;
    width: 70px;
    height: 100%;
    right: 0;
    top: 0;
}

.phone-map img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.phone-listings ul li.map-img {
    padding-right: 77px;
}

.txt-area {
    border-bottom: 1px solid #e7e7e7;
    padding: 12px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.txt-area span {
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 600;
}

.txt-area span a {
    color: #3472e1;
}

.offer-sec {
    width: 224px;
    border-radius: 10px;
    overflow: hidden;
    border: 1px solid #e7e7e7;
}

.offer-sec-img {
    height: 120px;
}

.offer-sec-img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.offer-sec-wrap {
    padding: 20px;
}

.offer-sec-txt {
    padding: 15px;
}

.offer-sec-txt h5 {
    font-size: 14px;
    font-weight: 600;
    color: #000;
}

.offer-sec-txt h6 {
    font-size: 11px;
    color: #000;
    font-weight: 600;
    padding: 5px 0 0 0;
}

.offer-sec-txt p {
    font-size: 11px;
}

.phone-scroll {
    max-height: 564px;
    overflow-x: hidden;
    overflow-y: auto;
}

.phone-scroll {
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.phone-scroll::-webkit-scrollbar {
    display: none;
}

.txtBtn {
    position: relative;
}

.txtBtn .theme-btn {
    position: absolute;
    right: 5px;
    bottom: 15px;
    padding: 9px 23px;
    font-size: 12px;
}